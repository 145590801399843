import {
  Button,
  Checkbox,
  Flex,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { theme } from "../../partials/utils/Contants";
import { useStore } from "../../store/store";
import Forms from "../../components/Forms/Forms";
import CreateCompanyFrom from "../../components/CreateCompany/CreateCompanyForm";
import useFetch from "../../partials/utils/Interceptor/Interceptor";

const EditBid = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const { id } = useParams();
  const { step, setStep } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState<any>();
  const [tabIndex, setTabIndex] = useState(step - 3);
  const [isBidConfirmed, setIsBidConfirmed] = useState(false);
  const [companyData, setCompanyData] = useState<any>();
  const [bidData, setBidData] = useState<any>();
  const handleTabsChange = (index: number) => {
    setTabIndex(index);
  };
  const handleConfirmBid = () => {
    if (!isBidConfirmed) {
      toast({
        title: `Please accept the terms and conditions.`,
        description: "",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
    } else {
      const id = localStorage.getItem("comp_id");
      useFetch
        .get(`confirmbid/${id}`)
        .then((res) => {
          toast({
            title: `Bid confirmed successfully`,
            description: "",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          localStorage.removeItem("comp_id");
          setStep(1);
          navigate("/bid-centre");
        })
        .catch((error) => {
          toast({
            title:  'Something went wrong',
            description: error?.response?.data?.message || "",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    }
  };
  function fetchBidData() {
    setIsLoading(true);
    useFetch
      .get(`editbid/${id}`)
      .then((res) => {
        setTimeout(() => {
          setIsLoading(false);
        }, 500);
        setCompanyData(res?.data?.company);
        setBidData(res?.data?.company?.bids);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    fetchBidData();
    setStep(3);
  }, [id]);
  useEffect(() => {
    setTabIndex(step - 3);
  }, [step]);
  return (
    <Flex
      direction="column"
      gap="20px"
      background="#f8f8f8"
      borderRadius="16px"
      padding="30px"
      w="100%"
      h="100%"
      overflow="auto"
    >
      <Text fontSize="1.2rem" fontWeight="700" color="#25396f">
        Edit Bid
      </Text>
      {isLoading ? (
        <Flex minH="160px" align="center" justify="center" w="100%">
          <Spinner size="lg" />
        </Flex>
      ) : (
        <Tabs
          index={tabIndex}
          onChange={handleTabsChange}
          variant="unstyled"
          width="100%"
        >
          <TabList gap="23px">
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              isDisabled={step !== 3}
            >
              Company Details
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              isDisabled={step !== 4}
            >
              Bid Information
            </Tab>
            <Tab
              height="47px"
              fontSize="15px"
              fontWeight="500"
              color={theme.color.GRAY_LABEL}
              _selected={{
                color: "black",
              }}
              isDisabled={step !== 5}
            >
              Confirmation
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="black"
            borderRadius="1px"
          />
          <TabPanels>
            <TabPanel>
              <CreateCompanyFrom editData={companyData} />
            </TabPanel>
            <TabPanel>
              <Forms editData={bidData} />
            </TabPanel>
            <TabPanel>
              <Flex direction="column" gap="30px" py="10px">
                <Text>
                  I/We, the undersigned, do hereby unconditionally agree,
                  understand and consent as follows:
                </Text>
                <Text>
                  I/We give Sanctuari permission to gather and use the data you
                  or your company has provided, as specified in the privacy
                  policy and the terms of use. I/We declare and acknowledge that
                  I/We have read and understood the terms of the and shall be
                  bound by the same.I/we give permission to the platform to
                  override the DNC in order to share information.
                </Text>
                <Checkbox
                  isChecked={isBidConfirmed}
                  onChange={(e) => setIsBidConfirmed(e.target.checked)}
                >
                  {" "}
                  I agree with the Terms and Conditions.
                </Checkbox>
                <Flex w="100%" justify="space-between" align="center" mt="20px">
                  <Button
                    type="button"
                    borderRadius="21px"
                    onClick={() => setStep(step - 1)}
                  >
                    Previous
                  </Button>
                  <Button
                    onClick={handleConfirmBid}
                    borderRadius="21px"
                    colorScheme="green"
                  >
                    Submit
                  </Button>
                </Flex>
              </Flex>
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </Flex>
  );
};

export default EditBid;
