import PowerImage from "../assets/images/rocket.png";
import GuidedImage from "../assets/images/supercase.png";
const BidModes = [
  {
    id: 1,
    title: "Power Mode",
    value: "Power",
    desc: "This is not your first rodeo and you have a lot of experience placing risks and do not need guidance regarding the nuances of submitting data and information for underwriting. ",
    icon: PowerImage,
  },
  {
    id: 2,
    title: "Guided Mode",
    value: "Guided",
    desc: "We have prepared your Ledger as a .csv file. All your conversations pertaining to your risk profiile have been included in this .csv file.",
    icon: GuidedImage,
  },
  // {
  //   id: 3,
  //   title: "Consulted Mode",
  //   value: "Consulted",
  //   desc: "We have prepared yourGMC Ledger as a .csv file. All your conversations pertaining to your risk profiile have been included in this .csv file.",
  // },
];
const EmployeeBenefitsList = [
  {
    id: 1,
    title: "Group Health Insurance",
    desc: "A group mediclaim policy provides health insurance coverage to a collective group, such as employees or organization members, covering risks like hospitalization expenses and medical treatments at lower premiums through group bargaining.",
  },
  {
    id: 2,
    title: "Group Personal Accident",
    desc: "Group Personal Accident Insurance offers coverage to a collective group, typically employees or organization members, against accidental injuries resulting in disability or death. It provides financial benefits to the insured or their beneficiaries in such unfortunate events.",
  },
  {
    id: 3,
    title: "Group Term Life",
    desc: "Group Term Life Insurance provides coverage to a collective group, often employees or organization members, offering financial protection to their beneficiaries in the event of the insured individual's death. It serves as a form of financial security and support for the insured's dependents.",
  },
  {
    id: 4,
    title: "Employee Deposit Linked Insurance",
    desc: "Employee Deposit Linked Insurance (EDLI) is a scheme offered under the Employees' Provident Fund Organization (EPFO) in India. It provides life insurance coverage to employees who are members of the EPF scheme. In the unfortunate event of the employee's demise, their beneficiaries receive a lump sum benefit.",
  },
  {
    id: 5,
    title: "Employees/Workmen Compensation Insurance",
    desc: "Workmen's Compensation Insurance provides coverage for employees who suffer work-related injuries or illnesses. It offers financial protection by compensating for medical expenses, lost wages, and disability benefits. Employers obtain this insurance to fulfill legal obligations and ensure the well-being of their....",
  },
  {
    id: 6,
    title: "Professional Indemnity Insurance",
    desc: "Professional Indemnity Insurance, procured by companies for their employees, safeguards against legal claims arising from professional negligence, errors, or omissions. It covers legal costs and compensation payouts, offering financial protection and peace of mind to both employees and employers in....",
  },
  {
    id: 7,
    title: "Group Critical Illness Insurance",
    desc: "Group Critical Illness Insurance, purchased by companies for their employees, provides financial protection in the event of serious illnesses like cancer, heart attack, or stroke. It offers a lump sum benefit to employees diagnosed with covered conditions, helping to alleviate financial burdens during challenging times.",
  },
  {
    id: 8,
    title: "Employee Liability Insurance",
    desc: "Employee Liability Insurance, acquired by companies for their employees, protects against legal claims related to employee actions or negligence resulting in injury, property damage, or financial losses to third parties. It provides coverage for legal expenses and compensation payouts, ensuring financial security.....",
  },
  {
    id: 9,
    title: "Employee Wellness Programs",
    desc: "Employee Wellness Programs, implemented by companies for their employees, promote health and well-being through initiatives such as fitness classes, health screenings, mental health support, and stress management resources. These programs aim to improve employee health, productivity, and......",
  },
];
const PropertyList = [
  {
    id: 1,
    title: "Standard Fire",
    desc: "Tailored Insurance covers material damage caused to property and interior by providing complete coverage to the insured.Fire insurance policy's subject matter can be any property with financial value, such as buildings (including plinth and foundation), plants and types of machinery, stocks, furniture, fixtures and fittings, other contents, stocks etc",
  },
  {
    id: 2,
    title: "Money",
    desc: "Money insurance is a specialized type of insurance that provides coverage for the loss or damage of money while in transit or within premises due to theft, accidents, or misfortune.It is help to protect the business that handle big amount of commercials.",
  },
  {
    id: 3,
    title: "Contractors Plant and Machinery",
    desc: "In the construction industry, plant and machinery are essential tools that facilitate the execution of projects efficiently and safely. These machines are designed to handle various tasks, from earthmoving and material handling to concrete mixing and lifting heavy loads. Their effective use significantly impacts project timelines, labour costs, and overall productivity.",
  },
  {
    id: 4,
    title: "Contractors All Risk",
    desc: "Contractor All Risk (CAR) insurance is a comprehensive policy designed to cover various risks associated with construction projects, including damage to the project, third-party liability, and optional coverage for delays in start-up.",
  },
  {
    id: 5,
    title: "Burglary",
    desc: "Burglary Insurance protects against loss of or damage to the insured property due to burglary and housebreaking. The Insurance pays actual loss/damage to your insured property caused by burglary/housebreaking subject to Sum Insured's limit.",
  },
];
const CyberList = [
  {
    id: 1,
    title: "Cyber Insurance",
    desc: "Cyber Liablity is a type of insurance policy designed to protect businesses from financial losses resulting from cyber attacks, data breaches, and other cybersecurity incidents.",
  },
];
const TravelList = [
  {
    id: 1,
    title: "Group Travel",
    desc: "Group travel insurance provides coverage for a group of people traveling together, offering protection against risks like medical emergencies, trip cancellations, lost or delayed baggage",
  },
];
const MarineList = [
  {
    id: 1,
    title: "Cargo Insurance",
    desc: "It offers complete protection against cargo loss or damage while in transit, including accidents, other unanticipated circumstances, and damage sustained during loading or unloading.",
  },
  {
    id: 2,
    title: "Import Export Insurance",
    desc: "When goods are lost or damaged during transit, this insurance helps to compensate importers and exporters.",
  },
  {
    id: 3,
    title: "Marine Hull Insurance",
    desc: "This marine insurance protects a vessel's body against loss or damage resulting from a variety of marine hazards. Ensuring the safety and integrity of their vessels during maritime operations is imperative for shipowners.",
  },
  {
    id: 4,
    title: "Freight Insurance",
    desc: "The loss of freight or cargo resulting from maritime hazards or other causes while in transit is covered by freight insurance. It can be taken by the shipowner, the consignor, or the consignee, depending on who bears the risk of cargo loss",
  },
  {
    id: 5,
    title: "Liability Insurance",
    desc: "A liability insurance policy covers the insured's legal responsibility for property damage or bodily harm to other people. It can be taken by shipowners, charterers, operators, managers, or other parties involved in maritime activities. Protection and Indemnity (P&I) Insurance and Charterers' Liability Insurance are two common forms of liability insurance.",
  },
  {
    id: 6,
    title: "Time Policy",
    desc: "This policy provides coverage for a predetermined period of time, like a year.",
  },
  {
    id: 7,
    title: "Voyage Policy",
    desc: "Coverage for a particular voyage is provided by this marine insurance policy. The policy ends when the journey or trip is finished.",
  },
  {
    id: 8,
    title: "Mixed Policy",
    desc: "Combining time and voyage policies, this policy covers travel between designated locations within a specified time frame.",
  },
  {
    id: 9,
    title: "Port Risk Policy",
    desc: "This type of marine insurance policy pays for any damage the ship sustains while moored in a port.",
  },
  {
    id: 10,
    title: "Valued Policy",
    desc: "The cargo value is predetermined under this type of marine insurance policy, and in the event that the cargo is lost, the policy reimburses the claim.",
  },
  {
    id: 11,
    title: "Floating Policy",
    desc: "Businesses that move goods frequently should consider this type of marine insurance coverage. It provides coverage for several trips up to a lump-sum maximum; the policyholder declares the value of the goods subtracted from the lump sum amount.",
  },
  {
    id: 12,
    title: "Composite Policy",
    desc: "There are several underwriters for this type of marine insurance policy, and each has a fixed liability.",
  },
  {
    id: 13,
    title: "Fleet Policy",
    desc: "A fleet of vessels owned by a single entity is covered by this type of marine insurance coverage.",
  },
];
const LiabilityList = [
  {
    id: 1,
    title: "Commercial General Liability",
    desc: "CGL Insurance is designed for business organisations to protect them against liability claims for bodily injury (BI) and property damage (PD) arising out of premises, operations, products, and completed operations",
  },
  {
    id: 2,
    title: "Lift Liability",
    desc: "Lift liability insurance, is a type of insurance policy designed to cover the legal liability of owners of passenger lifts in buildings towards third parties for personal injuries or property damage arising out of the use and operation of the lift",
  },
  {
    id: 3,
    title: "Directors & Offficers Insurance",
    desc: "A Directors and Officers Liability (D&O) policy is a specialized form of liability insurance designed to protect the personal financial interests of directors and officers of a company from legal actions alleging wrongful acts during their management tenure.",
  },
];

const InsuranceCompany = [
  "Acko General Insurance Limited",
  "Aditya Birla Health Insurance Co. Ltd.",
  "Aditya Birla SunLife Insurance Co. Ltd.",
  "Aegon Life Insurance Company Limited,",
  "Ageas Federal Life Insurance Company Limited,",
  "Aviva Life Insurance Company India Ltd.",
  "Bajaj Allianz General Insurance Company Limited",
  "Bajaj Allianz Life Insurance Co. Ltd.",
  "Bharti AXA Life Insurance Company Ltd,",
  "Canara HSBC Oriental Bank of Commerce Life Insurance Company Limited,",
  "Care Health Insurance Ltd(Formerly Known As Religare Health Insurance Co. Ltd.)",
  "Cholamandalam Ms General Insurance Co Ltd",
  "Ecgc Limited",
  "Edelweiss General Insurance Co. Ltd.",
  "Edelweiss Tokio Life Insurance Company Limited",
  "Future Generali India Insurance Co Ltd",
  "Future Generali India Life Insurance Company Limited,",
  "Go Digit General Insurance Limited",
  "Hdfc Ergo General Insurance Co.Ltd.",
  "HDFC Life Insurance Co. Ltd",
  "Icici Lombard General Insurance Co. Ltd.",
  "ICICI Prudential Life Insurance Co. Ltd,",
  "Iffco Tokio General Insurance Co. Ltd.",
  "IndiaFirst Life Insurance Company Ltd.,",
  "Kotak Mahindra General Insurance Company Limited",
  "Kotak Mahindra Life Insurance Co. Ltd.",
  "Liberty General Insurance Ltd.",
  "Magma Hdi General Insurance Co. Ltd.",
  "Manipal Cigna Health Insurance Company Limited",
  "Max Life Insurance Co. Ltd.",
  "National Insurance Co. Ltd.",
  "Navi General Insurance Limited",
  "Niva Bupa Health Insurance Co Ltd.",
  "PNB MetLife India Insurance Co. Ltd,",
  "Pramerica Life Insurance Co. Ltd.",
  "Raheja Qbe General Insurance Co. Ltd.",
  "Reliance General Insurance Co.Ltd",
  "Reliance Nippon Life Insurance ",
  "Royal Sundaram General Insurance Co. Ltd.",
  "Sahara India Life Insurance Co. Ltd.",
  "Sbi General Insurance Company Limited",
  "SBI Life Insurance Co. Ltd.",
  "Shriram General Insurance Company Limited",
  "Shriram Life Insurance Co. Ltd.",
  "Star Health & Allied Insurance Co.Ltd.",
  "Star Union Dai-Ichi Life Insurance Co. Ltd.",
  "TATA AIA Life Insurance Co. Ltd.",
  "Tata Aig General Insurance Co. Ltd.",
  "The New India Assurance Co. Ltd",
  "The Oriental Insurance Company Limited",
  "United India Insurance Company Limited",
  "Universal Sompo General Insurance Co. Ltd.",
];

const brokersCompany = [
  "3 Dimensional Insurance Brokers India Private Limited",
  "A D Naik Insurance Broking Private Limited",
  "A&M Ins. Brokers Pvt. Ltd.",
  "A2V Insurance Brokers Private Limited",
  "Aadeeshwara Insurance Brokers Private Limited",
  "Aapka Policywala Insurance Broking Services Private Limited",
  "Aapt Insurance Brokers Private Limited",
  "Aarvi Insurance Brokers Pvt. Ltd.",
  "Aastha Insurance Brokers Pvt. Ltd.",
  "Aavik Insurance Brokers Private Limited",
  "Ab Insurance Brokers Pvt. Ltd.",
  "Abhivridhi Insurance Brokers Pvt. Ltd.",
  "Access Insurance Broking Private Limited",
  "Accurate Risk Managers And Insurance Brokers Pvt. Ltd.",
  "Ace Ins. Brokers Pvt. Ltd.",
  "Achintya Insurance Brokers Private Limited",
  "Acme Insurance Broking Services P. Ltd.",
  "Acu 1St Choice Insurance Brokers Private Limited",
  "Acuvisor Insurance Brokers (India) Private Limited",
  "Add Value Insurance Broker Pvt. Ltd.",
  "Aditya Birla Insurance Brokers Ltd.",
  "Advance India Insurance Broker Services Ltd.",
  "Advance Life Insurance Brokers Pvt Ltd",
  "Aegis Insurance Brokers Private Limited",
  "Aequitas Insurance Brokers Pvt. Ltd.",
  "Afro-Asian Ins. & Reinsurance Brokers (India) Pvt. Ltd.",
  "Agile Ins. Brokers Pvt. Ltd.",
  "Ahalia Insurance Brokers Private Limited",
  "Aibo Insurance Brokers Limited",
  "Aibrom Insurance Broking Private Limited",
  "Aims Insurance Broking Pvt. Ltd.",
  "Aisect Insurance Broker Private Limited",
  "Akshaya Insurance Brokers Private Limited",
  "Alacrity Insurance Brokers Pvt Ltd",
  "Alankit Insurance Brokers Ltd",
  "Alegion Insurance Broking Pvt Ltd",
  "Alert Insurance Brokers Pvt Ltd",
  "Alexa Insurance Broker Private Limited",
  "Alliance Ins. Brokers Pvt. Ltd.",
  "Alps Insurance Brokers Private Limited",
  "Altius Tech Insurance Broking Private Limited",
  "Amaze Insurance Brokers Pvt. Ltd.",
  "Ambaasi Insurance Broking Llp",
  "Am-Ex Insurance Brokers (India) Pvt Ltd",
  "Amos L&C Insurance Brokers Private Limited",
  "Analah Insurance Broking Private Limited",
  "Anand Rathi Insurance Brokers Ltd.",
  "Anar Insurance Brokers Ltd",
  "Angelic Insurance Brokers Private Limited",
  "Anib-Essel Insurance Brokers Private Limited",
  "Antic Insurance Brokers & Risk Management Services Pvt. Ltd.",
  "Antworks Insurance Broking & Risk Consulting Pvt. Ltd.",
  "Anzen Insurance Broking Pvt Ltd",
  "Aon India Insurance Brokers Private Limited",
  "Aon Risk Insurance Brokers India Private Ltd",
  "Apac Insurance Broking Services (I) Private Ltd.",
  "Aparys Insurance Broking Private Limited",
  "Apd Insurance Brokers Pvt Ltd",
  "Apeejay Insurance Broking Services Pvt. Ltd.",
  "Apex Insurance Broking Services Pvt. Ltd.",
  "Apis Nandi Insurance Brokers Private Limited",
  "Apna Insurance Broking Consultants India Pvt. Ltd.",
  "Apna Policy Insurance Brokers Pvt Ltd",
  "Arihant Insurance Broking Services Limited",
  "Arion Insurance Brokers Pvt. Ltd.",
  "Arka Insurance Broking Private Limited",
  "Armourr Insurance Broking Private Limited",
  "Arron Insurance Brokers Private Limited",
  "Arrow Re- Insurance Broker Pvt. Ltd.",
  "Arunaya Insurance Brokers Pvt. Ltd.",
  "Aryav Insurance Broking Private Limited",
  "Ashutosh Insurance Brokers Llp",
  "Aspire Insurance Brokers Pvt. Ltd.",
  "Assan One Insurance Broker Private Limited",
  "Astro Ins. Brokers Ltd.",
  "Atelier Insurance Broking Private Limited",
  "Athena Insurance & Reinsurance Brokers Pvt. Ltd.",
  "Atlantis Insurance Brokers Private Limited",
  "Atlas Insurance Brokers (P) Ltd.",
  "Aum Bima Suraksha Broking Pvt Ltd",
  "Aum Insurance Brokers Pvt Ltd",
  "Authentic Ins. Brokers India Ltd.",
  "Auto Hangar Insurance Brokers Private Limited",
  "Auxillium Insurance Broking Private Limited",
  "Aventus Insurance Brokers Private Limited",
  "Avia Insurance Brokers Private Limited",
  "Axiom Ins Brokers Pvt. Ltd.",
  "Axon Insurance Broking Pvt Ltd",
  "Baid Solutions Insurance Broking Private Limited",
  "Bajaj Capital Ins. Broking Ltd.",
  "Balio Insurance Broking Private Limited",
  "Barik Insurance Brokers Private Limited",
  "Beacon Insurance Brokers Pvt. Ltd.",
  "Berkeley Insurance Brokers Ltd",
  "Berns Brett India Insurance Broking Pvt Ltd",
  "Bfsi Insurance Broking Private Limited",
  "Bhadratha Insurance Broking Services Private Limited",
  "Bharat Bhushan Insurance Brokers Private Limited",
  "Bharat Re- Insurance Brokers Pvt. Ltd.",
  "Big Brother Insurance Brokers India Private Limited",
  "Big Dipper Insurance Brokers (India) Private Limited",
  "Bihari Ji Insurance Broking Private Limited",
  "Bima Leap Insurance Broker Limited",
  "Bimagenie Insurance Broking Pvt. Ltd.",
  "Bimaguru Insurance Brokers Private Limited",
  "Bimakavach Insurance Broking Private Limited",
  "Bimapunj Insurance Brokers Private Limited",
  "Bimasansar Insurance Broking Private Limited",
  "Bizcovr Insurance Broking Private Limited",
  "Blue Crest Ins. Broking Pvt. Ltd.",
  "Blue Tree Global Insurance Brokers Private Limited",
  "Blue Umbrella Risk Management & Insurance Brokers Private Ltd.",
  "Bluearmour Insurance Brokers Private Limited",
  "Bluebells Insurance Broking Private Limited",
  "Bluechip Insurance Broking Private Limited",
  "Bp Risk Advisors & Insurance Brokers Pvt Ltd",
  "Bridgeinsure Global Insurance Broker Private Limited",
  "Brilliant Insurance Broking Services Pvt Ltd",
  "Brixton Insurance Broker Private Limited",
  "Bse Ebix Insurance Broking Private Limited",
  "Buckss Insurance Brokers Private Limited",
  "Bvsm India Insurance Brokers Pvt. Ltd",
  "Byr Insurance Brokers Pvt. Ltd.",
  "Candid Insurance Brokers Private Limited",
  "Capital Insurance Brokers Pvt Ltd",
  "Capitalsquare Insurance Brokers Pvt Ltd",
  "Capvirgo Insurance Broking And Services Private Limited",
  "Care4U Insurance Broking Private Limited",
  "Catalyst Insurance Brokers Pvt. Ltd.",
  "Centcart Insurance Broking Services Private Limited",
  "Centillion Insurance Brokers Limited",
  "Centrum Insurance Brokers Ltd.",
  "Certigo Insurance Brokers Private Limited",
  "Chansar Insurance Brokers Llp",
  "Charaka Insurance Broking Private Limited",
  "Chirag Insurance Brokers Private Limited",
  "Choice Insurance Broking India Private Limited",
  "Chqbook Insurance Broking Private Limited",
  "Citius Insurance Brokers Private Limited",
  "Ckp Insurance Brokers Pvt. Ltd.",
  "Clarion Insurance Broking Services Private Limited",
  "Cms Ins. Brokers Pvt. Ltd.",
  "Coastal Ins. Brokers Pvt. Ltd.",
  "Cocentric Insurance Broking Services Private Limited",
  "Colour Funds Insurance Brokings Private Limited",
  "Composite Insurance Brokers & Advisors Pvt. Ltd.",
  "Connect Insurance Broking Services Private Limited",
  "Corporate Risks India Insurance Brokers Pvt Ltd.",
  "Cosmos Ins. Brokers Pvt. Ltd.",
  "Coverfox Insurance Broking Pvt. Ltd.",
  "Covermitra Insurance Broking Pvt. Ltd",
  "Covernsave Insurance Brokers Private Limited",
  "Coverton Insurance Broking Private Limited",
  "Crc Insurance Broking Private Limited",
  "Crest Insurance Brokers Private Limited",
  "D.A.Y. Insurance Broker Pvt. Ltd.",
  "D2C Insurance Broking Pvt. Ltd.",
  "Damika Insurance Broking Co. Pvt. Ltd.",
  "Dearn Insurance Broker Private Limited",
  "Deccan Insurance & Reinsurance Brokers Pvt. Ltd.",
  "Derisq Insurance Brokers Private Limited",
  "Desteneer Insurance Brokers Private Limited",
  "Digisafe Insurance Broking Private Limited",
  "Digisecure Insurance Broking And Risk Management Services Private Limited",
  "Dios Insurance Brokers Private Limited",
  "Dis Insurance Broking Private Limited",
  "Disha Capital Insurance Brokers Private Limited",
  "Dolphin Insurance Broking Services Private Limited",
  "Dossa Insurance And Broking Services Ltd.",
  "Drv Insurance Brokers Private Ltd.",
  "Dubbal Insurance Broker Private Limited",
  "Ecomoney Insurance Bokers Pvt. Ltd.",
  "Edify Insurance Brokers Pvt. Ltd.",
  "Ef Insurance Broking Pvt Ltd",
  "Efficient Ins. Brokers Pvt. Ltd.",
  "Eksure Insurance Brokers Private Limited",
  "Elite Insurance Brokers Pvt. Ltd.",
  "Elitegeneral Insurance Brokers Private Limited",
  "Embee Insurance Brokers Ltd",
  "Emedlife Insurance Broking Services Ltd.",
  "Emerge Insurance Borker And Consultancy Services Private Limited",
  "Enrichwise Insurance Broking Services Private Limited",
  "Ensign Insurance Brokers Pvt. Ltd.",
  "Ensureti Insurance Broking Pvt. Ltd.",
  "Epix Insurance Brokers Private Limited",
  "Epoch Insurance Brokers Pvt. Ltd.",
  "Equirus Insurance Broking Pvt Ltd",
  "Ethika Insurance Broking Pvt Ltd.",
  "Eureka Insurance Broking Pvt Ltd",
  "Excel Insurance Broking Pvt. Ltd.",
  "Excellent Insurance Broking Services Limited",
  "Exclusive Ins. Broking Services Ltd.",
  "Experiya Insurance Brokers Llp",
  "Extra Cover Ins. Brokers Pvt. Ltd.",
  "Ezy Wise Services Insurance Brokers Private Limited",
  "Fa Premium Insurance Broking Private Limited",
  "Fair Deal Ins. Brokers Pvt. Ltd.",
  "Fairways Insurance Brokers Private Limited",
  "Fame Insurance Broking Services Pvt Ltd",
  "Fiable Insurance Broking Private Limited",
  "Fiducial Insurance Brokers India Pvt Ltd",
  "Findola Insurance Brokers Private Limited",
  "Finhaat Insurance Broking Private Limited",
  "First Advisor Insurance Brokers Pvt. Ltd.",
  "First Equity Digital Insurance Broking Private Limited",
  "First Insurance World Broking Services Private Limited",
  "First Policy Insurance Brokers Pvt. Ltd.",
  "Fortune Five Insurance Brokers Pvt Ltd",
  "Four Brothers Express Insurance Brokers Private Limited",
  "Fouress Insurance Broking Services Pvt. Ltd.",
  "Fs Insurance Broking Services Private Ltd",
  "Fund Tree Insurance Brokers Pvt. Ltd.",
  "Futurance India Insurance Brokers Pvt. Ltd.",
  "Future First Insurance Broking Pvt. Ltd.",
  "Futurisk Insurance Broking Co. Pvt Ltd.",
  "G.K. Insurance Brokers Private Limited",
  "Gallagher Insurance Brokers Private Limited",
  "Garuda Insurance Broker Pvt. Ltd.",
  "Gennext Insurance Brokers Pvt. Ltd.",
  "Gepl Insurance Broking Pvt. Ltd",
  "Get Paz Insurance Brokers Private Limited",
  "Gilpa Insurance Brokers",
  "Ginteja Insurance Brokers Private Limited",
  "Girnar Insurance Brokers Private Limited",
  "Glaze Insurance Brokers Private Limited",
  "Glide Insurance Broking Services Pvt Ltd",
  "Global India Insurance Brokers Pvt. Ltd.",
  "Globesecure Insurance Brokers Private Limited",
  "Go Insure India Insurance Broking Private Limited",
  "Godspeed Insurance Broking Private Limited",
  "Gold Leaf Insurance Brokers Private Limited",
  "Golden M P Insurance Brokers Private Limited",
  "Goldkey Insurance Brokers Pvt. Ltd.",
  "Gomti Insurance Broking Pvt. Ltd.",
  "Good Faith Ins. Brokers Pvt. Ltd.",
  "Good Insurance Brokers Pvt Ltd",
  "Goodx Insurance Brokers Pvt Ltd",
  "Gosree Insurance Broking Services Pvt. Ltd.",
  "Gram Tarang Insurance Broking Private Limited",
  "Gramcover Insurance Brokers Private Limited",
  "Greenlife Insurance Broking Pvt. Ltd.",
  "Grit Insurance Brokers Private Limited",
  "Groupfit Insurance Brokers Private Limited",
  "Grovalue Insurance Broker Pvt Ltd",
  "Grover Insurance Brokers Private Limited",
  "Growease Insurance Brokers Private Limited",
  "Growthamax Insurance Broking Pvt Ltd",
  "Gulabi Nagari Insurance Brokers Private Limited",
  "Gvr Insurance Brokers Pvt. Ltd.",
  "Gwc India Insurance Broking Private Limited",
  "Hakani Bimasolutions Insurance Brokers Llp",
  "Hallmark Insurance Brokers Llp",
  "Hamara Bima Insurance Brokers Private Limited",
  "Harita Insurance Broking Llp",
  "Harmony Insurance Broking Pvt. Ltd.",
  "Havmore Ins. Brokers Pvt. Ltd.",
  "Hbr Insurance Brokers Pvt Ltd",
  "Hedge Guard India Insurance Broking Private Limited",
  "Helios Insurance Broking Services Pvt. Ltd",
  "Heritage Insurance Brokers Pvt. Ltd.",
  "Hero Insurance Broking India Private Limited",
  "Heute And Morgen Insurance Broker Private Limited",
  "High Ninez Insurance Brokers Private Limited",
  "Hii Insurance Broking Service Pvt. Ltd.",
  "Hindustan Insurance Brokers Ltd.",
  "Hitech Insurance Broking Services Ltd",
  "Hoard Insurance Brokers Llp",
  "Hopebox Insurance Broking Pvt. Ltd.",
  "Howden Ins. Brokers India Pvt. Ltd.",
  "Hudson Ins. Brokers Pvt. Ltd.",
  "Hyundai India Insurance Broking Private Limited",
  "Icare Insurance Broking Services Pvt Ltd",
  "Icm Insurance Brokers Pvt Ltd",
  "Ideal Ins. Brokers Pvt. Ltd.",
  "Ignite Insurance Broking Pvt Ltd",
  "Image Ins. Brokers Pvt. Ltd.",
  "Imn Insurance Broking Private Limited",
  "Impetus Insurance Brokers Pvt. Ltd.",
  "In2Fin Insurance Brokers Private Limited",
  "India Insure Risk Management And Insurance Broking Services Pvt Ltd",
  "Indian Insurance Broking Service",
  "Indira Insurance Brokers Private Limited",
  "Inditrade Insurance Broking Pvt. Ltd.",
  "Indus Insurance Broking Llp",
  "Infina Insurance Broking Pvt. Ltd.",
  "Infina Reinsurance Broking Private Limited",
  "Inshora Risk Advisory And Insurance Broking Pvt Ltd",
  "Instapolicy Insurance Broking Pvt. Ltd.",
  "Insucare India Insurance Broking Private Limited",
  "Insugo Insurance Brokers Privated Limited",
  "Insure First Insurance Brokers Llp",
  "Insurebee Insurance Brokers Pvt. Ltd.",
  "Insuregram Insurance Brokers Pvt Ltd",
  "Insurex Insurance Brokers Private Limited",
  "Insuright Insurance Broking Pvt Ltd",
  "Insutech Ins. Broking Services Pvt. Ltd.",
  "Intact Insurance Brokers Pvt. Ltd.",
  "Integrated Insurance Broking Services Pvt. Ltd.",
  "Integrated Risk Ins. Brokers Ltd.",
  "Interlink Insurance And Reinsurance Broking Pvt. Ltd.",
  "International Reinsurance & Insurance Consultancy & Broking Services Pvt Ltd",
  "Intl Risk Consultants Insurance Brokers Pvt. Ltd.",
  "Investacc Insurance Brokers Pvt. Ltd.",
  "Invoq Loop Insurance Broking Pvt Limited",
  "I-Pro Infinity Insurance Broking Services Llp",
  "Irc Insurance Broking Services Pvt Ltd",
  "Irm Insurance Brokers Pvt. Ltd.",
  "Isecure Insurance Brokers Pvt. Ltd.",
  "Itus Insurance Brokers Pvt. Ltd.",
  "Iva Insurance Broking Private Limited",
  "Iyogi Insuretech Insurance Brokers Private Limited",
  "J.B.Boda Insurance & Reinsurance Brokers Pvt Ltd",
  "Jai Ins. Brokers Pvt. Ltd.",
  "Jaika Ins. Brokerage Pvt. Ltd.",
  "Jainuine Insurance Brokers Pvt. Ltd.",
  "Jayantha Insurance Broking Private Limited",
  "Jio Insurance Broking Limited",
  "Jk Insurance Brokers Limited",
  "Jp Insurance Brokers Pvt. Ltd.",
  "Jrk Insurance Broking Pvt. Ltd.",
  "Jubilee Ins. Broking Services Pvt. Ltd.",
  "Jubiliant Insurance Broking Services Pvt Ltd.",
  "K Wasan Insurance Broking Private Limited",
  "K.M. Dastur Reinsurance Brokers Pvt. Ltd.",
  "Kadel Insurance Brokers Pvt. Ltd.",
  "Kalptaru Ins. Brokers Ltd.",
  "Kan Ins. Brokers Pvt. Ltd.",
  "Kapalin Insurance Broking Pvt. Ltd.",
  "Kapccure Insurance Brokers Private Limited",
  "Kare India Ins. Brokers Pvt. Ltd.",
  "Karmik Insurance Brokers Private Limited",
  "Kataria Insurance Brokers Pvt. Ltd.",
  "Kausalyam Insurance Broking Private Limited",
  "Kedpro Insurance Broking Private Limited",
  "Key Ins. Brokers Pvt. Ltd.",
  "Kig Insurance Brokers Private Limited",
  "Klr Proteger Insurance Broker Private Limited",
  "Kokoro Insurance Brokers Private Limited",
  "Krishiv Insurance Broking Llp",
  "K-Secure Risk Management & Insurance Broking Pvt. Ltd.",
  "L & G Ins. Brokers Ltd.",
  "Lambach Insurance Brokers Pvt. Ltd.",
  "Landmark Insurance Brokers Pvt Ltd.",
  "Latin Manharlal Insurance Broking Pvt. Ltd.",
  "Le Dieu Insurance Brokers Pvt Ltd.",
  "Leads Insurance Brokers Pvt. Ltd.",
  "Let'S Secure Insurance Brokers Private Limited",
  "Life & General Insurance & Reinsurance Brokers Pvt Ltd.",
  "Lifemart Insurance Brokers Pvt Ltd",
  "Limitless Insurance Broking Services Pvt Ltd",
  "Link-K Ins. Broker Co. Pvt. Ltd.",
  "Lion Insurance Brokers Private Limited",
  "Livlong Insurance Brokers Limited",
  "Lmb Ins. Brokers Pvt. Ltd.",
  "Lmv Insurance Broking Services Private Limited",
  "Logic Insurance Brokers Private Limited",
  "Lords Insurance Brokers Llp",
  "Lords Mark Insurance Broking Services Private Limited",
  "Lorven Insurance Broking Services Pvt. Ltd.",
  "M.B. Boda Reinsurance Brokers Pvt.Ltd.",
  "Madhav Ins. Brokers Pvt. Ltd.",
  "Madhuvan Ins. Broking Services Pvt. Ltd.",
  "Magnum Insurance Broking Pvt. Ltd.",
  "Mahindra Insurance Brokers Ltd.",
  "Mainland Insurance Brokers Pvt Ltd",
  "Makhathi Insurance Broker Pvt. Ltd.",
  "Maklav Insurance Brokers Private Limited",
  "Manappuram Insurance Brokers Limited",
  "Manas Insurance Brokers Pvt Ltd",
  "Mankad & Associates Insurance Broking Pvt. Ltd.",
  "Marsh India Insurance Brokers Pvt. Ltd",
  "Maruti Suzuki Insurance Broking Private Limited",
  "Massive Insurance Brokers Private Limited",
  "Master Insurance Brokers Ltd",
  "Mathsons Re- Insurance Brokers Pvt. Ltd.",
  "Mazel Insurance Brokers Pvt Ltd",
  "Mdh Insurance Broking Pvt. Ltd.",
  "Medplus Insurance Brokers P Ltd",
  "Merabima Insurance Brokers Private Limited",
  "Mercury Insurance Brokers Pvt. Ltd",
  "Metier Insurance Broking Private Limited",
  "Mialtus Insurance Broking Private Limited",
  "Microtap Insurance Broking India Limited",
  "Migoo Insurance Brokers Private Limited",
  "Miles Insurance Brokers Pvt Ltd",
  "Millennials Insurance Brokers Private Limited",
  "MITIGATA INSURANCE BROKERS PRIVATE LIMITED",
  "Mkt Insurance Brokers Pvt. Ltd.,",
  "Mohur Insurance Broking And Services Pvt. Ltd.)",
  "Monetic Insurance Brokers Private Limited",
  "Moneys Spidery Insurance Broker Private Limited",
  "Moon Insurance Brokers Private Limited",
  "Multinsure Insurance Brokers Private Limited",
  "Muthoot Insurance Brokers Pvt. Ltd.",
  "Muthoot Risk Insurance And Broking Services Pvt. Ltd.",
  "Mutual Global Insurance Broking Private Limited",
  "Myanmol Insurance Brokers Llp",
  "Myriad Insurance Brokers Limited",
  "Nandak Insurance Brokers Private Limited",
  "Nandina Insurance Broking Pvt Ltd",
  "Narayan Insurance Brokers Pvt. Ltd.",
  "Naresh Kalra Insurance Brokers Llp",
  "Navinchandra Insurance Broking Private Limited",
  "Navnit Insurance Broking Pvt. Ltd.",
  "Nd Insurance Broking Pvt Ltd",
  "Nearby Insurance Broking Services Private Limited",
  "Neem Tree Insurance Broking & Advisory Pvt Ltd",
  "Neo Risk Management Andinsurance Brokers Private Limites",
  "Neotec Insurance Brokers Limited",
  "New Era Insurance Broking Services Pvt. Ltd",
  "New Quest Ins. Broking Services Ltd.",
  "New Theme Insurance Brokers Pvt Ltd",
  "Newgen Insurance Broking Pvt. Ltd.",
  "Nhance India Insurance Broking Private Limited",
  "Nihs Insurance Brokers Limited",
  "Nipun Ins. Brokers Pvt. Ltd.",
  "Nirmal Bang Insurance Broking Private Limited",
  "Nirviann Insurance Brokers Private Limited",
  "Nj Insurance Brokers Pvt Ltd",
  "Notion Insurance Broker Pvt. Ltd.",
  "Novabenefits Insurance Brokers Private Limited",
  "Novelty Insurance Brokers Ltd",
  "Novo Insurance Broking Services Private Limited",
  "Ntrust Insurance Broking Services Private Limited",
  "Nz Insurance Brokers Pvt. Ltd.",
  "O J Insurance Brokers Pvt. Ltd.",
  "Oasis Insurance Brokers Pvt Ltd",
  "Octagon Insurance Broking Private Limited",
  "Odin Insurance Broking Services Pvt. Ltd.",
  "Ohm Maruthi Insurance Broking Services Pvt. Ltd.",
  "Omega Insurance Broking Services Pvt.Ltd.",
  "Onyx Insurance Brokers Private Limited",
  "Open World Insurance Broking Ltd.",
  "Opp Insurance Brokers Private Limited",
  "Optima Insurance Brokers Pvt Ltd.",
  "Optimum Ins. Broking Services Pvt. Ltd.",
  "Orange Capital Insurance Broking Private Limited",
  "Origin Insurance Brokers India Pvt Ltd",
  "P&A Insurance Brokers Private Limited",
  "Paavana Insurance Brokers Pvt Ltd",
  "Palm Insurance Brokers Pvt. Ltd.",
  "Parv Insurance Brokers Private Limited",
  "Paytm Insurance Broking Private Limited",
  "Pcs Insurance Broking Pvt. Ltd.",
  "Pearl Ins. Broker Pvt. Ltd.",
  "Peraj Ins. Brokers Pvt. Ltd.",
  "Phonepe Insurance Broking Services Pvt Ltd",
  "Pib Insurance Brokers Private Limited",
  "Pinewood Insurance Brokers Private Limited",
  "Pioneer Insurance & Reinsurance Brokers Pvt Ltd.",
  "Planswell Insurance Brokers Private Limited",
  "Platinumone Insurance Broking Pvt. Ltd.",
  "Plum Benefits Insurance Brokers Private Limited",
  "Pmp Insurancebroking Llp",
  "Policicue Insurance Brokers Private Limited",
  "Policy Adda Insurance Brokers Private Limited",
  "Policy Cart Insurance Broker Private Limited",
  "Policy Era Insurance Broking Pvt. Ltd.",
  "Policy Expert Insurance Broker Pvt Ltd",
  "Policy Pact Insurance Broker Private Limited",
  "Policy Parivaar Insurance Brokers Private Limited",
  "Policy Planner Insurance Brokers Private Limited",
  "Policy Wings Insurance Broking Private Limited",
  "Policy.Online Insurance Brokers Private Limited",
  "Policybazaar Insurance Brokers Private Limited",
  "Policyboat Insurance Brokers Private Limited",
  "Policyinn Insurance Brokers Pvt Ltd",
  "Policypoint Insurance Broking Private Limited",
  "Policyportal Risk Management And Insurance Brokers Private Limited",
  "Pragmatic Insurance Broking Services Pvt. Ltd.",
  "Pratiraksha 4Ever Insurance Brokers Private Limited",
  "Preferred Partners Insurance Brokers Pvt. Ltd.",
  "Premia Insurance Brokers Private Limited",
  "Premium Insurance Brokers Pvt Ltd",
  "Prisha Insurance Brokers Private Limited",
  "Privity Insurance Brokers Pvt. Ltd.",
  "Probitas Insurance Brokers Pvt. Ltd.",
  "Probus Insurance Broker Private Limited",
  "Profins Insurance Broking Pvt. Ltd.",
  "Prost Insurance Brokers Pvt Ltd",
  "Protect India Insurance Broking Services Pvt. Ltd.",
  "Providence India Insurance Broking Private Limited.",
  "Provincial Insurance Broking Pvt. Ltd.",
  "Prudent Ins. Brokers Pvt. Ltd.",
  "Psa Insurance Brokers Private Limited",
  "Punarnava Insurance Broking Private Limited",
  "Pureassure Insurance Brokers Pvt. Ltd.",
  "Purple Pond Insurance Brokers Pvt. Ltd.",
  "Qian Insurance Broking Llp",
  "Quickinsure Insurance Brokers Pvt. Ltd.",
  "R E T R O Insurance Brokers Private Limited",
  "R K Global Insurance Brokers Ltd.",
  "R&D Insurance Brokers Private Limited",
  "Radiant Insurance Broking Private Limited",
  "Raghnall Insurance Broking & Risk Management Pvt Ltd",
  "Raghunandan Insurance Brokers Pvt Ltd",
  "Rahhe Insurance Brokers Llp",
  "Raintree Insurance Broking Private Limited",
  "Rajesh Chetan Insurance Brokers Pvt Ltd",
  "Rajkrishna Ins. Broker Pvt. Ltd.",
  "Ramp Insurance Brokers Pvt. Ltd.",
  "Ratnaafin Insurance Broking Private Ltd",
  "Rba Ins. Brokers Pvt. Ltd.",
  "Rd&A Insurance Broking Services Pvt Ltd",
  "Real Insurance Brokers Pvt. Ltd.",
  "Regal Ins. Brokers & Risk Management Services Pvt. Ltd.",
  "Regiis Insurance Brokers Private Limited",
  "Reliassure Insurance Brokers Pvt. Ltd.",
  "Relitrade Insurance Broking Pvt Ltd",
  "Relleno Insurance Broking Services Pvt.Ltd.",
  "Rest Assured Insurance Broking Pvt. Ltd.",
  "Revfin Insurance Broking Private Limited",
  "Review Insurance Brokers Pvt. Ltd.",
  "Rexa Insurance Broking Services Pvt. Ltd.",
  "Rfl Insurance Broking Pvt.Ltd.",
  "Ria Ins. Brokers Pvt. Ltd.",
  "Right Horizons Insurance Broking Services Pvt. Ltd.",
  "Rinupolzy Insurance Broking Private Limited",
  "Rion Insura Insurance Broking Private Limited",
  "Rishabh Insurance Brokers Pvt. Ltd.",
  "Risk Care Insurance Broking Services Pvt Ltd",
  "Risk Managers Insurance Broking Private Limited",
  "Risk Shield Insurance Broking Limited",
  "Riskaide Insurance Brokers Private Limited",
  "Riskbirbal Insurance Brokers Private Limited",
  "Riskiq Insurance Brokers Private Limited",
  "Riverbank Insurance Brokers Private Limited",
  "Riverpool Insurance Brokers Private Limited",
  "Rm Insurance Broking Private Limited",
  "Rms Arc Insurance Brokers Pvt. Ltd.",
  "Robinhood Insurance Broker Pvt.Ltd",
  "Rock Sure Insurance Brokers Private Limited",
  "Royal Ins. Broking (India) Pvt. Ltd.",
  "Rr Insurance Brokers Pvt. Ltd.",
  "Rrhlk Insurance Broking Services Pvt Ltd",
  "Rtech Insurance Broker Private Limited",
  "Rtf Insurance Brokers Private Limited",
  "S & R Ins. Brokers Pvt. Ltd.",
  "S&S General Insurance Brokers (India) Ltd.",
  "Saferisk Insurance Brokers Pvt. Ltd.",
  "Sai Point Insurance Brokers Private Limited",
  "Saiprabha Insurance Brokers Private Limited",
  "Sairam Insurance Brokers Private Limited",
  "Sakthi Pelican Insurance Broking Private Limited",
  "Salasar Services Insurance Brokers Pvt. Ltd.",
  "Samavesh Insurance Brokers Private Limited",
  "Samay Insurance Brokers Pvt. Ltd.",
  "Sambhavit Insurance Brokers Private Limited",
  "Samyag Insurance Broking Llp",
  "Sana Insurance Brokers Private Limited",
  "Sanguine Insurance Brokers Pvt. Ltd.",
  "Sanjeevani Ins. Brokers Pvt. Ltd.",
  "Sanshi Insurance Brokers Pvt. Ltd.",
  "Sansom Insurance Brokers Pvt. Ltd.",
  "Sanyog Insurance Brokers India Private Limited",
  "Saral Insurance Broking Pvt. Ltd.",
  "Sargam Insurance Brokers Pvt. Ltd.",
  "Sarsa Insurance Brokers Pvt. Ltd.",
  "Sarvpriya Insurance Brokers Pvt. Ltd.",
  "Satabdi Ins. Broker Ltd.",
  "Saumit Insurance Brokers Private Limited",
  "Savefin Insurance Brokers Pvt. Ltd.",
  "Savvvyy Insurance Brokers Private Limited",
  "Sbm Insurance Brokers Pvt Ltd",
  "Securenow Insurance Broker Pvt Ltd",
  "Securisk Insurance Brokers Private Limited",
  "Security Insurance Brokers [India] Pvt Ltd.",
  "Securus Insurance Brokers (India ) Pvt Ltd",
  "Seguro Insurance Brokers Private Limited",
  "Sejal Insurance Broking Ltd",
  "Share India Insurance Brokers Private Limited",
  "Shield Insurance Broking Solution Pvt. Ltd.",
  "Shift Risk Insurance Brokers Limited",
  "Shoonya Insurance Brokers Pvt Ltd",
  "Shree Balaji Insurance Brokers Private Limited",
  "Shree Dhyan Insurance Brokers Private Limited",
  "Shreyash Ins. Brokers Pvt. Ltd.",
  "Shrigoda Insurance Brokers Limited",
  "Shrinithi Insurance Broking Private Limited",
  "Shubhechha Insurance Broking Private Limited",
  "Siddharth Insurance Brokers Pvt. Ltd.",
  "Sigma Ins. Broking Pvt. Ltd.",
  "Signature Insurance Brokers Pvt. Ltd.",
  "Signior Purebima Insurance Brokers Private Limited",
  "Sipany Ss Insurance Brokers Llp",
  "Sivana Insurance Brokers Pvt Ltd",
  "Sixth Sense Insurance Broking Services Pvt Ltd",
  "Sj Insurance Brokers Private Limited",
  "Skd Insurance Broking & Services Pvt. Ltd",
  "Skyfire Insurance Broking Llp",
  "Skylite Direct Insurance Brokers Pvt. Ltd",
  "Slk Bima Brokers Pvt. Ltd.",
  "Smarttech Insurance Brokers Llp",
  "Smc Insurance Brokers Pvt Ltd.",
  "Sonnen Ins. Broking Servies Pvt. Ltd.",
  "Sorigin Insurance Brokers Private Limited",
  "Spa Insurance Broking Services Ltd",
  "Spearhead Insurance Broking Private Limited",
  "Spectrum Insurance Broking Private Limited",
  "Spinny Insurance Broker Private Limited",
  "Springdale Insurance Broking Pvt. Ltd.",
  "Sps Consultants Insurance Brokers Llp",
  "Square Insurance Brokers Pvt. Ltd.",
  "Srei Insurance Broking Ltd",
  "Srg Insurance Brokers Pvt. Ltd.",
  "Sri Prathinik Insurance Broking Pvt. Ltd.",
  "Sridhar Ins. Broker Pvt. Ltd.",
  "Sriyah Insurance Brokers Pvt. Ltd.",
  "Ss Insurance Brokers Private Limited",
  "Ssg Insurance Brokers And Risk Managers Private Limited",
  "Sss Risk Management And Insurance Brokers Private Limited",
  "Star Insurance Brokers Limited",
  "Stenhouse Insurance Brokers Pvt Ltd",
  "Strategic Insurance Broking Services Pvt. Ltd",
  "Strive Insurance Brokers Private Limited",
  "Sun Risk Management And Insurance Broking Services Pvt. Ltd.",
  "Suprasesh General Insurance Services & Brokers Pvt Ltd,",
  "Supremacy Insurance Brokers Private Limited",
  "Suraksha Insurance Brokers Pvt. Ltd.",
  "Surakshachakra Insurance Brokers Pvt. Ltd.",
  "Sushil Insurance Brokers Pvt Ltd",
  "Suvidha Insurance Broking Pvt. Ltd",
  "Suyash Insurance Broking Llp",
  "Svojas Insurance Broking And Risk Management Services Private Limited",
  "Swastika Insurance Broking Services Limited",
  "Symbo India Insurance Broking Private Limited",
  "Synergy Ins. Broking Services Pvt. Ltd.",
  "System-Ethics Insurance Brokers Pvt. Ltd.",
  "Tamarind Insurance Brokers Private Limited",
  "Tarjani Insurance Broking Pvt Ltd",
  "Tata Motors Insurance Broking And Advisory Services Limited",
  "Technopolis Insurance Brokers Pvt. Ltd.",
  "Telos Risk Management & Ins. Broking Services(P) Ltd.",
  "Telsysweb Insurance Brokers Private Limited",
  "Terranua Insurance Brokers Private Limited",
  "Tofico Insurance Broking Pvt Ltd.,",
  "Topspot Insurance Broking Private Limited",
  "Torque Insurance Brokers Private Limited",
  "Tower Insurance & Reinsurance Brokers (India) Pvt. Ltd.",
  "Toyota Tsusho Insurance Broker (India) Pvt Ltd",
  "Trailblazer Insurance Broker Private Limited",
  "Transindia Ins. Broking & Risk Management Pvt. Ltd.",
  "Trinity Reinsurance Brokers Ltd.",
  "Troth Insurance Broking And Consultants Pvt. Ltd.",
  "Trovity Insuretech Insurance Broking Services Pvt Ltd",
  "Trustegic Insurance Broking Private Limited",
  "Trustline Insurance Brokers Pvt. Ltd.",
  "Trustlink Insurance Brokers Private Ltd",
  "Tt Insurance Broking Services Pvt. Ltd",
  "Turtlemint Insurance Broking Services Private Limited",
  "Tvs Insurance Broking Private Limited",
  "Uib Insurance Brokers (India) Pvt Ltd",
  "Unicorn Insurance Brokers Pvt. Ltd.",
  "Unilight Insurance Brokers Private Limited",
  "Unilight Insurance Brokers Pvt. Ltd.",
  "Unison Ins. Broking Services Pvt. Ltd.",
  "United Risk Ins. Broking Co. Pvt. Ltd.",
  "Upsure Insurance Brokers Private Limited",
  "Upwisery Insurance Brokers Private Limited",
  "V2C Insurance Brokers Pvt Ltd",
  "Vachan Insurance Broking Private Limited",
  "Valley Insurance Brokers Private Limited",
  "Vedant Insurance Broking Private Limited",
  "Veezinsure Insurance Broking Private Limited",
  "Velocity Insurance Broking Services Private Limited",
  "Vgk Insurance Brokers Private Limited",
  "Vibe Insurance Broking And Advisory Service Private Limited",
  "Vibhuti Insurance Brokers Pvt. Ltd.",
  "Vibrant Insurance Broking Pvt. Ltd.",
  "Vipul Ins. Brokers Pvt. Ltd.",
  "Virtual Galaxy Insurance Brokers Private Limited",
  "Virtual Insurance Broking Entity Private Limited",
  "Vishrag Insurance Broking Services Private Ltd.",
  "Vision Ins. Risk Analysis Management & Brokers Pvt. Ltd.",
  "Visionmap Insurance Broking Private Limited",
  "Visista Insurance Broking Services Pvt. Ltd.",
  "Vitrak Insurance Brokers Private Limited",
  "Vittachaya Insurance Brokers Private Limited",
  "Vizza Insurance Broking Services P.Ltd",
  "Volo Insurance Broking Services Pvt. Ltd.",
  "Vprotectall Insurance Broking Private Limited",
  "Vrddhi Insurance Broking Services India Pvt Ltd",
  "Vulcan Int. Insurance Brokers Ltd.",
  "Wai Insurance Broking Services Pvt. Ltd.",
  "Way2Wealth Insurance Brokers Pvt. Ltd.",
  "Wealth Bucket Insurance Brokers Pvt. Ltd.",
  "Wealth Guardian Insurance Brokers Pvt Ltd",
  "Wealthkart Insurance Broking Private Limited",
  "Wealthstreet Insurance Brokers Private Limited",
  "Wellbe Insurance Brokers (India) Private Limited",
  "Wellfare Insurance Broker Private Limited",
  "Welltech Insurance Brokers Pvt. Ltd.",
  "Westford Insurance Brokers & Risk Managers Pvt. Ltd.",
  "Wheelseye Insurance Broking Private Limited",
  "White Horse Insurance Broker Private Limited",
  "Willis Towers Watson India Insurance Brokers Pvt. Ltd",
  "Winstic Insurance Brokers (India) Private Limited",
  "Wisdom Insurance Broking Pvt Ltd",
  "Wmg Risk Advisory And Insurance Broking Services Pvt Ltd",
  "Worldwide Ins. Brokers Ltd.",
  "Xperitus Insurance Brokers Pvt. Ltd",
  "Yella Insurance Broking Private Limited",
  "Zeal Insurance Brokers Private Limited",
  "Zen Ins. Brokers Pvt. Ltd.",
  "Zenmoney Insurance Broking Services Pvt. Ltd.",
  "Zfinca Insurance Brokers Pvt Ltd",
  "Zoom Insurance Brokers Pvt Ltd",
  "Zopper Insurance Brokers Private Limited",
  "Zuari Insurance Brokers Ltd.",
];

export {
  BidModes,
  EmployeeBenefitsList,
  PropertyList,
  MarineList,
  LiabilityList,
  CyberList,
  TravelList,
  InsuranceCompany,
  brokersCompany,
};
