import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Select,
  Radio,
  RadioGroup,
  Checkbox,
  Button,
  Text,
  VStack,
  HStack,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { FaCloudDownloadAlt, FaInfoCircle } from "react-icons/fa";
import { useStore } from "../../../store/store";
import "../form.css";
import useFetch from "../../../partials/utils/Interceptor/Interceptor";
import { useEffect } from "react";
import { flattenObject } from "../../../partials/utils/helper";
interface InsuranceFormValues {
  insured: string;
  Want_to_cover_parents: string;
  employeenumber: number;
  spousenumber: number;
  kidsnumber: number;
  policytype: string;
  insurer: string;
  comp_location: string;
  coverage_type: string;
  coverage_type_name_other?: string;
  sum_insured?: string;
  base_sum_insured?: string;
  base_sum_insured_other?: string;
  Age_Limit: number;
  payment_mode: string;
  previous_insurer?: string; // Optional
  previous_policy_expiry?: string; // Optional
  Special_conditions?: string;
  Payout_Options: string;
  Earning_Spouse: any;
  Terminal_illness_benefit: any;
  v_a_sum_assured: any;
  Group_Term_in_lieu_of_EDLI: any;
  Single_rate_benefit: any;
  Sum_Assured_Reset_Benefit: any;
  Profit_sharing: any;
  start_date: string;
  end_date: string;
  policy_start_date: string;
  policy_end_date: string;
  expected_premium: number;
  claim_data: any;
  emp_data: any;
}

const GroupTermLife = ({ product, editData, mode }: any) => {
  const toast = useToast();
  const { step, setStep } = useStore();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<InsuranceFormValues>({
    defaultValues: {
      coverage_type: "Flat cover",
      start_date: new Date().toISOString().split("T")[0],
      end_date: new Date(new Date().setDate(new Date().getDate() + 10))
        .toISOString()
        .split("T")[0],
      Age_Limit: 60,
    },
  });
  const onSubmit = async (data: any) => {
    const compId = editData?.id
      ? editData?.company_id
      : localStorage.getItem("comp_id");
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      const value = data[key];

      if (value instanceof FileList) {
        Array.from(value).forEach((file) => {
          formData.append(key, file as Blob);
        });
      } else {
        formData.append(key, value as string | Blob);
      }
    });

    formData.append("comp_id", compId.toString() || "");
    formData.append("product", product);

    try {
      const response = await useFetch.post("bid", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast({
        title: `Bid ${editData?.id ? "updated" : "created"} successfully`,
        description: "Bid will be in draft till confirmation",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      setStep(5);
    } catch (error) {
      console.error("Error:", error);

      toast({
        title: `Submission failed`,
        description: "There was an error creating the bid.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  useEffect(() => {
    if (editData) {
      const flattenedData = flattenObject(editData);
      for (let key in flattenedData) {
        if (flattenedData.hasOwnProperty(key)) {
          setValue(key as keyof InsuranceFormValues, flattenedData[key]);
        }
      }
    }
  }, [editData]);
  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack gap={4} align="stretch">
          {/* Insured */}
          <HStack gap={4}>
            <FormControl id="insured" isInvalid={Boolean(errors?.insured)}>
              <FormLabel>Whom do you want to insure?*</FormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("insured", {
                  required: "Insured is required",
                })}
                placeholder="Whom do you want to insure ?"
              >
                <option value="Employee">Employee</option>
                <option value="Employee & Spouse">Employee & Spouse</option>
                <option value="Employee ,Spouse & Kids">
                  Employee ,Spouse & Kids
                </option>
              </Select>
              {errors?.insured && (
                <Text color="red.500" fontSize="sm">
                  {errors?.insured?.message}
                </Text>
              )}
            </FormControl>

            {/* Want to cover parents */}
            <FormControl
              id="Want_to_cover_parents"
              isInvalid={Boolean(errors?.Want_to_cover_parents)}
            >
              <FormLabel>Want to cover parents?*</FormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("Want_to_cover_parents", {
                  required: "This field is required",
                })}
                defaultValue="No"
              >
                <option value="No">No</option>
                <option value="Yes">Yes</option>
              </Select>
              {errors?.Want_to_cover_parents && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Want_to_cover_parents?.message}
                </Text>
              )}
            </FormControl>
          </HStack>

          <HStack gap={4}>
            {/* Employee Count */}
            <FormControl
              id="employeenumber"
              isInvalid={Boolean(errors?.employeenumber)}
            >
              <FormLabel>Employee Count</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("employeenumber")}
              />
              {errors?.employeenumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.employeenumber?.message}
                </Text>
              )}
            </FormControl>

            {/* Spouse Count */}
            <FormControl
              id="spousenumber"
              isInvalid={Boolean(errors?.spousenumber)}
            >
              <FormLabel>Spouse Count</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("spousenumber")}
              />
              {errors?.spousenumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.spousenumber?.message}
                </Text>
              )}
            </FormControl>
          </HStack>

          <HStack gap={4}>
            {/* Kids Count */}
            <FormControl
              id="kidsnumber"
              isInvalid={Boolean(errors?.kidsnumber)}
            >
              <FormLabel>Kids Count</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                defaultValue="0"
                {...register("kidsnumber")}
              />
              {errors?.kidsnumber && (
                <Text color="red.500" fontSize="sm">
                  {errors?.kidsnumber?.message}
                </Text>
              )}
            </FormControl>
            {/* Policy Type */}
            <FormControl
              id="policytype"
              isInvalid={Boolean(errors?.policytype)}
            >
              <FormLabel>Policy Type*</FormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("policytype", {
                  required: "Policy type is required",
                })}
                placeholder="Select policy type"
              >
                <option value="New">New</option>
                <option value="Renewal">Renewal</option>
              </Select>
              {errors?.policytype && (
                <Text color="red.500" fontSize="sm">
                  {errors?.policytype?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
          <HStack gap={4}>
            {/* Bidder Preference */}
            <FormControl id="insurer" isInvalid={Boolean(errors?.insurer)}>
              <FormLabel>Bidder Preference*</FormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("insurer", {
                  required: "Bidder preference is required",
                })}
                placeholder="Preference"
              >
                <option value="Both">Both</option>
                <option value="Brokers">Brokers</option>
                <option value="INS_COMP">Insurance Company</option>
              </Select>
              {errors?.insurer && (
                <Text color="red.500" fontSize="sm">
                  {errors?.insurer?.message}
                </Text>
              )}
            </FormControl>
            {/* Company Location */}
            <FormControl
              id="comp_location"
              isInvalid={Boolean(errors?.comp_location)}
            >
              <FormLabel>Where is your company located?*</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("comp_location", {
                  required: "Company location is required",
                })}
              />
              {errors?.comp_location && (
                <Text color="red.500" fontSize="sm">
                  {errors?.comp_location?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
          {/* Benefits Section */}
          <Box borderTop="1px solid" mt={6} pt={4}>
            <Text fontWeight="bold" color="darkorchid">
              Benefits
            </Text>
          </Box>

          <HStack gap={4} align="center">
            {/* Coverage Type */}
            <FormControl
              id="coverage_type"
              isInvalid={Boolean(errors?.coverage_type)}
            >
              <FormLabel>Coverage Type*</FormLabel>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("coverage_type", {
                  required: "Coverage type is required",
                })}
                placeholder="Select coverage type"
              >
                <option value="Flat cover">Flat cover</option>
                <option value="Graded Cover">Graded Cover</option>
                <option value="Multiple of Salary">Multiple of Salary</option>
                <option value="Others">Others</option>
              </Select>
              {errors?.coverage_type && (
                <Text color="red.500" fontSize="sm">
                  {errors?.coverage_type?.message}
                </Text>
              )}
            </FormControl>
            {watch("coverage_type") === "Others" && (
              <FormControl
                id="coverage_type_name_other"
                isInvalid={Boolean(errors?.coverage_type)}
              >
                <FormLabel>Coverage Type Name*</FormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="text"
                  {...register("coverage_type_name_other", {
                    required: "Other name is required",
                  })}
                />

                {errors?.coverage_type_name_other && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.coverage_type_name_other?.message}
                  </Text>
                )}
              </FormControl>
            )}

            {/* Sum Insured Category */}
            <FormControl
              id="base_sum_insured"
              isInvalid={
                Boolean(errors?.base_sum_insured)
                // Boolean(errors?.sum_insured)
              }
            >
              <RadioGroup defaultValue="flat">
                <HStack gap={2}>
                  <Radio
                    value="flat"
                    fontSize="10px"
                    isDisabled
                    checked={watch("coverage_type") === "Flat cover"}
                  >
                    Sum insured
                  </Radio>
                  <Radio
                    value="specific"
                    isDisabled
                    fontSize="10px"
                    checked={watch("coverage_type") !== "Flat cover"}
                  >
                    Sum insured based on category
                  </Radio>
                </HStack>
              </RadioGroup>
              {watch("coverage_type") === "Flat cover" ? (
                <HStack>
                  <Select
                    bg="white"
                    borderRadius="21px"
                    mt={4}
                    placeholder="Select employee sum insured"
                    {...register("base_sum_insured", {
                      required: "Please Select the Base Sum insured",
                    })}
                  >
                    <option value="2500000">25 Lacs</option>
                    <option value="5000000">50 Lacs</option>
                    <option value="10000000">1 Cr</option>
                    <option value="20000000">2 Cr</option>
                    <option value="Others">Others</option>
                  </Select>
                  {watch("base_sum_insured") === "Others" && (
                    <FormControl
                      isInvalid={Boolean(errors?.base_sum_insured_other)}
                    >
                      <FormLabel>Value for Others*</FormLabel>
                      <Input
                        {...register("base_sum_insured_other", {
                          required: "This field is required",
                        })}
                        borderRadius="16px"
                        background="white"
                      />
                      {errors?.base_sum_insured_other && (
                        <Text color="red.500" fontSize="sm">
                          {errors?.base_sum_insured_other?.message}
                        </Text>
                      )}
                    </FormControl>
                  )}
                </HStack>
              ) : (
                <Input
                  bg="white"
                  borderRadius="21px"
                  mt={4}
                  type="number"
                  min="0"
                  // defaultValue="60"
                  {...register("base_sum_insured")}
                  isDisabled={true}
                />
              )}
              {errors?.base_sum_insured && (
                <Text color="red.500" fontSize="sm">
                  {errors?.base_sum_insured?.message}
                </Text>
              )}
            </FormControl>
          </HStack>

          <HStack gap={4}>
            {/* Age Limit */}
            <FormControl id="Age_Limit" isInvalid={Boolean(errors?.Age_Limit)}>
              <FormLabel>Age Limit*</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="number"
                min="0"
                {...register("Age_Limit", {
                  required: "Age limit is required",
                })}
              />
              {errors?.Age_Limit && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Age_Limit?.message}
                </Text>
              )}
            </FormControl>

            {/* Payment Mode */}
            <FormControl
              id="payment_mode"
              isInvalid={Boolean(errors?.payment_mode)}
            >
              <Flex align="start">
                <FormLabel> Premiuim Payment Mode*</FormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Pay the premium using one of the following payment options: monthly, quarterly, half-yearly, or annual."
                  />
                )}
              </Flex>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("payment_mode", {
                  required: "Payment mode is required",
                })}
                placeholder="Select payment mode"
              >
                <option value="One-time">One-time</option>
                <option value="Annual">Annual</option>
                <option value="Quarterly">Quarterly</option>
                <option value="Monthly">Monthly</option>
              </Select>
              {errors?.payment_mode && (
                <Text color="red.500" fontSize="sm">
                  {errors?.payment_mode?.message}
                </Text>
              )}
            </FormControl>
          </HStack>
          <HStack gap={4}>
            {/* Payout Options */}
            <FormControl
              id="Payout_Options"
              isInvalid={Boolean(errors?.Payout_Options)}
            >
              <Flex align="start">
                <FormLabel> Payout Options*</FormLabel>
                {mode === "Guided" && (
                  <FaInfoCircle
                    fontSize="11px"
                    color="gray.500"
                    title="Return of premiums is referred to as a payout option. There are two typical ways to obtain the amount: an income option, in which the nominee receives payment over a specific time period, and a lump sum amount that is given to the nominee following the policyholder's death."
                  />
                )}
              </Flex>
              <Select
                bg="white"
                borderRadius="21px"
                {...register("Payout_Options", {
                  required: "Payout option is required",
                })}
                placeholder="Select payout option"
              >
                <option value="Lump sum Income">Lump sum Income</option>
                <option value="Income period (In Years)">
                  Income period (In Years)
                </option>
              </Select>
              {errors?.Payout_Options && (
                <Text color="red.500" fontSize="sm">
                  {errors?.Payout_Options?.message}
                </Text>
              )}
            </FormControl>

            {/* Special Conditions */}
            <FormControl id="Special_conditions">
              <FormLabel>Special conditions, if any</FormLabel>
              <Input
                bg="white"
                borderRadius="21px"
                type="text"
                {...register("Special_conditions")}
              />
            </FormControl>
          </HStack>
          {/* Optional Benefits */}
          <Box borderTop="1px solid " mt={4} pt={4}>
            <Text fontWeight="bold" color="darkorchid" mb="4">
              Optional Benefits
            </Text>
            <HStack justify="space-evenly">
              {/* Voluntary Additional Sum Assured */}
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  {...register("v_a_sum_assured")}
                  value="Voluntary Additional Sum Assured benefit"
                >
                  <Flex align="start">
                    <FormLabel mb="0" fontWeight="500">
                      {" "}
                      Voluntary Additional Sum Assured
                    </FormLabel>
                    {mode === "Guided" && (
                      <FaInfoCircle
                        fontSize="11px"
                        color="gray.500"
                        title="Every Member covered by the Master Policy has the option to upgrade their benefits and raise their Sum Assured above what the Employer offers."
                      />
                    )}
                  </Flex>
                </Checkbox>
              </FormControl>

              {/* Terminal Illness Benefit */}
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  {...register("Terminal_illness_benefit")}
                  value="Terminal illness benefit"
                >
                  <Flex align="start">
                    <FormLabel mb="0" fontWeight="500">
                      {" "}
                      Terminal illness
                    </FormLabel>
                    {mode === "Guided" && (
                      <FaInfoCircle
                        fontSize="11px"
                        color="gray.500"
                        title="When a terminal illness is diagnosed, this benefit expedites the entire Sum Assured (end stage of life)."
                      />
                    )}
                  </Flex>
                </Checkbox>
              </FormControl>

              {/* Earning Spouse */}
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  {...register("Earning_Spouse")}
                  value="Earning Spouse"
                >
                  <Flex align="start">
                    <FormLabel mb="0" fontWeight="500">
                      {" "}
                      Earning Spouse
                    </FormLabel>
                    {mode === "Guided" && (
                      <FaInfoCircle
                        fontSize="11px"
                        color="gray.500"
                        title="Subject to insurable interest, the Company will extend Cover to the Member's Earning Guardian and Earning Spouse."
                      />
                    )}
                  </Flex>
                </Checkbox>
              </FormControl>
            </HStack>
            <HStack justify="space-evenly" my="3">
              {/* Group Term in lieu of EDLI */}
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  {...register("Group_Term_in_lieu_of_EDLI")}
                  value="Group Term in lieu of EDLI"
                >
                  <Flex align="start">
                    <FormLabel mb="0" fontWeight="500">
                      {" "}
                      Group Term in lieu of EDLI
                    </FormLabel>
                    {mode === "Guided" && (
                      <FaInfoCircle
                        fontSize="11px"
                        color="gray.500"
                        title="EDLI: The Employees Deposit Linked Insurance Scheme, also known as EDLI, is an insurance plan offered to salaried employees in the private sector who are EPFO members by the Employees Provident Fund Organization."
                      />
                    )}
                  </Flex>
                </Checkbox>
              </FormControl>

              {/* Single Rate Benefit */}
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  {...register("Single_rate_benefit")}
                  value="Single rate benefit"
                >
                  <Flex align="start">
                    <FormLabel mb="0" fontWeight="500">
                      Single rate
                    </FormLabel>
                    {mode === "Guided" && (
                      <FaInfoCircle
                        fontSize="11px"
                        color="gray.500"
                        title="All new members who join the group prior to the next renewal date of the policy. There is no additional fee / charge for this facility."
                      />
                    )}
                  </Flex>
                </Checkbox>
              </FormControl>

              {/* Sum Assured Reset Benefit */}
              <FormControl display="flex" alignItems="center">
                <Checkbox
                  {...register("Sum_Assured_Reset_Benefit")}
                  value="Sum Assured Reset Benefit"
                >
                  <Flex align="start">
                    <FormLabel mb="0" fontWeight="500">
                      Sum Assured Reset
                    </FormLabel>
                    {mode === "Guided" && (
                      <FaInfoCircle
                        fontSize="11px"
                        color="gray.500"
                        title="Throughout the duration of the Master Policy, the Master Policyholder may raise or lower the Sum Assured for each Member."
                      />
                    )}
                  </Flex>
                </Checkbox>
              </FormControl>

              {/* Profit Sharing */}
            </HStack>
            <FormControl display="flex" alignItems="center">
              <Checkbox {...register("Profit_sharing")} value="Profit sharing">
                <FormLabel mb="0" fontWeight="500">
                  Profit sharing
                </FormLabel>
              </Checkbox>
            </FormControl>
          </Box>
          <Flex
            borderTop="1px solid "
            direction="column"
            w="100%"
            mt={4}
            pt={4}
            gap="10px"
          >
            {/* Bid Dates */}
            <HStack gap={4}>
              {/* Bid Start Date */}
              <FormControl
                id="start_date"
                isInvalid={Boolean(errors?.start_date)}
              >
                <FormLabel>Bid's Start Date*</FormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  {...register("start_date", {
                    required: "Bid's start date is required",
                  })}
                />
                {errors?.start_date && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.start_date?.message}
                  </Text>
                )}
              </FormControl>

              {/* Bid End Date */}
              <FormControl id="end_date" isInvalid={Boolean(errors?.end_date)}>
                <FormLabel>Bid's End Date*</FormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  {...register("end_date", {
                    required: "Bid's end date is required",
                  })}
                />
                {errors?.end_date && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.end_date?.message}
                  </Text>
                )}
              </FormControl>
            </HStack>

            {/* Policy Dates */}
            <HStack gap={4}>
              {/* Policy Start Date */}
              <FormControl id="policy_start_date">
                <FormLabel>Policy Start Date</FormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  {...register("policy_start_date")}
                />
              </FormControl>

              {/* Policy End Date */}
              <FormControl id="policy_end_date">
                <FormLabel>Policy End Date</FormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="date"
                  {...register("policy_end_date")}
                />
              </FormControl>
            </HStack>
            <HStack gap={4}>
              {/* Anticipated Premium */}
              <FormControl
                id="expected_premium"
                isInvalid={Boolean(errors?.expected_premium)}
              >
                <Flex align="start">
                  <FormLabel> Anticipated premium*</FormLabel>
                  {mode === "Guided" && (
                    <FaInfoCircle
                      fontSize="11px"
                      color="gray.500"
                      title="Anticipated premium for acquiring this policy."
                    />
                  )}
                </Flex>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  defaultValue="0"
                  {...register("expected_premium", {
                    required: "Anticipated premium is required",
                  })}
                />
                {errors?.expected_premium && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.expected_premium?.message}
                  </Text>
                )}
              </FormControl>

              {/* Total Coverages */}
              <FormControl
                id="sum_insured"
                isInvalid={Boolean(errors?.sum_insured)}
              >
                <FormLabel>Total coverages*</FormLabel>
                <Input
                  bg="white"
                  borderRadius="21px"
                  type="number"
                  min="0"
                  defaultValue="0"
                  {...register("sum_insured", {
                    required: "Total coverages is required",
                  })}
                />
                {errors?.sum_insured && (
                  <Text color="red.500" fontSize="sm">
                    {errors?.sum_insured?.message}
                  </Text>
                )}
              </FormControl>
            </HStack>
            {/* File Uploads */}
            <HStack gap={4} my="3">
              {/* Claim Data */}
              <FormControl id="claim_data" flex={1}>
                <FormLabel>Claim Data of last year</FormLabel>

                <Input
                  bg="white"
                  borderRadius="21px"
                  type="file"
                  // display="none"
                  pt="5px"
                  {...register("claim_data")}
                />
              </FormControl>

              {/* Employee Data */}
              <HStack flex={1} gap={4} align="center">
                {" "}
                <FormControl id="emp_data" flex={2}>
                  <FormLabel>Employee Information</FormLabel>

                  <Input
                    bg="white"
                    borderRadius="21px"
                    type="file"
                    pt="5px"
                    accept=".xlsx"
                    {...register("emp_data")}
                  />
                </FormControl>
                <FormControl flex={1}>
                  <Button
                    as="a"
                    href="/path/to/sample-file.xlsx"
                    download
                    leftIcon={<FaCloudDownloadAlt />}
                    colorScheme="green"
                    bg="green.500"
                    borderRadius="21px"
                    size="sm"
                  >
                    <Text>Sample File</Text>
                  </Button>
                </FormControl>
              </HStack>
            </HStack>

            {/* Previous Insurer and Policy Expiry */}
            {watch("policytype") === "Renewal" && (
              <HStack gap={4}>
                {/* Previous Insurer */}
                <FormControl
                  id="previous_insurer"
                  isInvalid={Boolean(errors?.previous_insurer)}
                >
                  <FormLabel>Previous Insurer*</FormLabel>
                  <Input
                    bg="white"
                    borderRadius="21px"
                    {...register("previous_insurer", {
                      required: "Previous insurer is required",
                    })}
                  />
                  {errors?.previous_insurer && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.previous_insurer?.message}
                    </Text>
                  )}
                </FormControl>

                {/* Previous Policy Expiry */}
                <FormControl
                  id="previous_policy_expiry"
                  isInvalid={Boolean(errors?.previous_policy_expiry)}
                >
                  <FormLabel>Previous policy expiry*</FormLabel>
                  <Input
                    bg="white"
                    borderRadius="21px"
                    type="date"
                    {...register("previous_policy_expiry", {
                      required: "Previous policy expiry is required",
                    })}
                  />
                  {errors?.previous_policy_expiry && (
                    <Text color="red.500" fontSize="sm">
                      {errors?.previous_policy_expiry?.message}
                    </Text>
                  )}
                </FormControl>
              </HStack>
            )}
          </Flex>

          <Flex w="100%" justify="space-between" align="center" mt="20px">
            <Button
              type="button"
              borderRadius="21px"
              onClick={() => setStep(step - 1)}
            >
              Previous
            </Button>
            <Button type="submit" borderRadius="21px" colorScheme="green">
              Next
            </Button>
          </Flex>
        </VStack>
      </form>
    </Box>
  );
};

export default GroupTermLife;
