import { useNavigate } from "react-router-dom";
import {
  Button,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

import { RxCross1 } from "react-icons/rx";
import { FiEye } from "react-icons/fi";
import { RiEditCircleLine } from "react-icons/ri";

import { theme } from "../../partials/utils/Contants";
import { ChangeEvent, useRef, useState } from "react";
import useClickOutside from "../../hooks/useClickOutside";

import { createPortal } from "react-dom";
import moment from "moment";
import useFetch from "../../partials/utils/Interceptor/Interceptor";
import { ReactComponent as PdfIcon } from "../../partials/assets/icons/pdf-icon.svg";
import "./BidderBidCard.css";
import { IoCloudDownloadOutline } from "react-icons/io5";
import { BiDownload } from "react-icons/bi";
import { addCommas } from "../../partials/utils/helper";
import { FaBuilding } from "react-icons/fa6";
type BidderBidCardProps = {
  data: any;
  type: "draft" | "live" | "engaged" | "completed" | "inactive";
  fetchData?: any;
  selectedBid?: any;
  setSelectedBid?: any;
  bidId: number;
  bidderCount?: any;
  completeData?: any;
  setShowQuoteList?: any;
  pdfFile?: any;
  quoteFile?: any;
  quote?: any;
  fetchAll: () => void;
  engagedStatus?: string;
};
const ActionDropdown = [
  { icon: FiEye, label: "Requirements", event: "test" },
  { icon: RiEditCircleLine, label: "Quote", event: "test" },
  { icon: RiEditCircleLine, label: "Show Interest", event: "test" },
  { icon: RiEditCircleLine, label: "Policy Upload", event: "test" },
  // { icon: RiEditCircleLine, label: "Mark as completed", event: "test" },
];
const filterActions = (type: BidderBidCardProps["type"]) => {
  switch (type) {
    case "live":
      return ActionDropdown.filter((action) =>
        ["Requirements", "Show Interest"].includes(action.label)
      );
    case "engaged":
      return ActionDropdown.filter((action) =>
        [
          "Requirements",
          "Quote",
          "Policy Upload",
          "Mark as completed",
        ].includes(action.label)
      );
    case "completed":
      return ActionDropdown.filter((action) =>
        ["Preview"].includes(action.label)
      );
    default:
      return [];
  }
};
const BidderBidCard = ({
  data,
  type,
  fetchData,
  selectedBid,
  setSelectedBid,
  bidId,
  bidderCount,
  completeData,
  pdfFile,
  quoteFile,
  quote,
  fetchAll,
  engagedStatus,
}: BidderBidCardProps) => {
  const navigate = useNavigate();
  const toast = useToast();
  const {
    isOpen: isPolicyOpen,
    onOpen: onPolicyOpen,
    onClose: onPolicyClose,
  } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState({ id: "", value: false });
  const buttonRef = useRef<any>(null);
  const menuRef = useClickOutside(() => setIsOpen({ id: "", value: false }));
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const filteredActions = filterActions(type);

  const mouseClick = (e: any) => {
    e.stopPropagation();
    if (buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX - 100,
      });
    }
  };
  const toggleDropdown = () => {
    if (isOpen.value) {
      setIsOpen({ id: "", value: false });
    } else {
      setIsOpen({ id: data?.id || "1", value: true });
    }
  };
  const handleShowInterest = () => {
    useFetch
      .get(`showinterest/${bidId}`)
      .then((response) => {
        fetchAll();
        toast({
          title: "Interest showed successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Something went",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  const showDetail = (data: any) => {
    setSelectedBid({ id: bidId, action: "requirements" });
    toggleDropdown();
  };
  const handleEngaged = (label: string) => {
    if (label === "Quote") {
      navigate(`/bid-centre/quote/${data?.id}`);
    } else if (label === "Policy Upload") {
      onPolicyOpen();
    } else if (label === "Mark as completed") {
      // handleMarkComplete();
    }
  };
  function handleMarkComplete(companyId: number, userId: number) {
    useFetch
      .get(`changestatus/${companyId}/${userId}`)
      .then((res) => {
        fetchData();
        toast({
          title: "Completed Successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Something went wrong",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  }
  const handleDownloadPDF = (
    id: string | number,
    type: "quote-file" | "policy-file"
  ) => {
    useFetch
      .get(`download-${type}/${id}`, { responseType: "blob" })
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `policy_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast({
          title: "Downloaded Successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        console.error("Error downloading the PDF:", error);
        toast({
          title: "Dowload failed!",
          description: `${
            error?.response?.data?.message ||
            "There was an issue Downloading pdf. Please try again."
          }`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // trigger click on file input
    }
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };
  const handleUploadPDF = () => {
    if (!file) {
      toast({
        title: "Please select a pdf to upload",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("policy_file", file);
    useFetch
      .post(`quotes/${quote?.id}/policy_files`, formData, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((res) => {
        toast({
          title: "Upload successful!",
          description: "Policy uploaded successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onPolicyClose();
        fetchAll();
      })
      .catch((error) => {
        console.log("error", error?.response);
        let message =
          error?.response?.data?.message ||
          "There was an issue uploading your quote. Please try again.";
        toast({
          title: "Upload failed!",
          description: message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Flex
      direction="column"
      gap="17px"
      width="100%"
      className={
        selectedBid?.id === bidId ? "bid-card-selected" : "bid-card-bg"
      }
      // bg={selectedBid?.id===bidId ? 'black': ''}
      padding="12px"
      position="relative"
    >
      <Flex width="100%" justify="space-between" align="center">
        <Flex width="100%" align="center" gap="8px">
          <Flex
            bg={type === "live" ? theme.color.GREEN : theme.color.GREEN}
            height="15px"
            width="15px"
            borderRadius="full"
          />
          <Text
            fontSize="11px"
            fontWeight="400"
            color={
              selectedBid?.id === bidId
                ? "rgba(131, 131, 131, 1)"
                : "rgba(0, 0, 0, 0.50)"
            }
          >
            {type === "live"
              ? "Active"
              : type === "completed"
              ? "Completed"
              : "Paused"}{" "}
            |{" "}
            {data?.start_date
              ? moment(data?.start_date).format("D MMMM, yyyy")
              : "N/A"}{" "}
            -{" "}
            {data?.end_date
              ? moment(data?.end_date).format("D MMMM, yyyy")
              : "N/A"}
          </Text>
        </Flex>
        {type === "engaged" ? (
          <Text
            bg={engagedStatus === "Accepted" || engagedStatus === "Quote Accepted" ? "green" : theme.color.YELLOW}
            color="white"
            shadow="md"
            padding="5px 12px 5px 12px"
            borderRadius="12px"
            // height="21px"
            // width="60px"
            fontWeight="500"
            textAlign="center"
            whiteSpace="nowrap"
            fontSize="11px"
          >
            {engagedStatus}
          </Text>
        ) : (
          <Text
            bg="black"
            color={selectedBid?.id === bidId ? "white" : "#F6F6F6"}
            shadow="md"
            padding="2px 10px 3px 10px"
            borderRadius="12px"
            height="21px"
            width="auto"
            fontWeight="500"
            textAlign="center"
            whiteSpace="nowrap"
            border={
              selectedBid?.id === bidId
                ? "1px solid rgba(131, 131, 131, 1)"
                : "1px solid #F6F6F6"
            }
            fontSize="11px"
          >
            {bidderCount || "0"} bidders
          </Text>
        )}
      </Flex>
      <Flex direction="column" gap="5px" w="100%">
        <Flex width="100%" align="center" gap="8px">
          <Text
            fontSize="15px"
            fontWeight="700"
            color={selectedBid?.id === bidId ? "white" : "black"}
            whiteSpace="nowrap"
          >
            #{bidId} {data?.product} |
          </Text>
          <Text
            fontSize="12px"
            fontWeight="700"
            color={
              selectedBid?.id === bidId
                ? "rgba(131, 131, 131, 1)"
                : theme.color.GRAY_LABEL
            }
            whiteSpace="nowrap"
          >
            {type === "completed"
              ? `Premium Amount: ₹${addCommas(data?.quote_file?.claim_amount)}`
              : quote?.claim_amount && quote?.is_accepted === 1
              ? `Premium: ₹${addCommas(quote?.claim_amount)}`
              : `Expected Premium: ₹${addCommas(data?.expected_premium)}`}
          </Text>
        </Flex>
        {quote?.is_accepted === 1 && quote?.insurance_company_name && (
          <Flex align="center" gap="5px">
            <FaBuilding
              color={
                selectedBid?.id === bidId
                  ? "rgba(131, 131, 131, 1)"
                  : theme.color.GRAY_LABEL
              }
            />

            <Text
              fontSize="13px"
              fontWeight="600"
              color={
                selectedBid?.id === bidId
                  ? "rgba(131, 131, 131, 1)"
                  : theme.color.GRAY_LABEL
              }
              textTransform="capitalize"
            >
              {quote.insurance_company_name}
            </Text>
          </Flex>
        )}
      </Flex>
      <Flex width="100%" align="center" justify="space-between">
        <Flex width="100%" align="center" gap="8px">
          <Flex
            padding="4px 14px"
            align="center"
            justify="center"
            borderRadius="12px"
            bg="white"
            fontSize="10px"
            fontWeight="500"
            color="black"
          >
            Type: {data?.policy_type}
          </Flex>
          {false && (
            <Flex
              padding="4px 14px"
              align="center"
              justify="center"
              borderRadius="12px"
              bg={theme.color.GREEN}
              fontSize="10px"
              fontWeight="500"
              color="#F6F6F6"
              textTransform="capitalize"
            >
              {type === "draft" ? "Drafted" : type}
            </Flex>
          )}
        </Flex>
        <Flex gap="10px">
          {type === "completed" && (
            <Flex gap="8px">
              {quoteFile && (
                <Button
                  leftIcon={<PdfIcon />}
                  size="xs"
                  fontSize="10px"
                  borderRadius="16px"
                  onClick={() =>
                    handleDownloadPDF(data?.quote_file?.quote_id, "quote-file")
                  }
                  colorScheme="green"
                  bg={theme.color.GREEN}
                >
                  Download Proposal
                </Button>
              )}
              {pdfFile && (
                <Button
                  leftIcon={<PdfIcon />}
                  size="xs"
                  fontSize="10px"
                  borderRadius="16px"
                  onClick={() =>
                    handleDownloadPDF(data?.quote_file?.quote_id, "policy-file")
                  }
                  colorScheme="green"
                  bg={theme.color.GREEN}
                >
                  Download Policy
                </Button>
                // <Flex align="end" gap="2px">

                //   <Icon
                //     fontSize="18px"
                //     as={PdfIcon}
                //     color={selectedBid?.id === data?.id ? "white" : "black"}
                //     cursor="default"
                //   />
                //   <Text
                //     fontSize="14px"
                //
                //     color="rgba(131, 131, 131, 1)"
                //     whiteSpace="nowrap"
                //     fontWeight="bold"
                //     cursor="default"
                //   >
                //     Policy
                //   </Text>
                //   <Icon
                //     fontSize="15px"
                //     as={BiDownload}
                //     color={selectedBid?.id === data?.id ? "white" : "black"}
                //     cursor="pointer"
                //     onClick={() => handleDownloadPDF(bidId, "policy-file")}
                //   />
                // </Flex>
              )}
            </Flex>
          )}
          {type !== "completed" && (
            <Flex direction="column" position="relative">
              <Flex
                ref={buttonRef}
                w="99px"
                transition="all 0.2s"
                borderRadius="24px"
                borderWidth="1px"
                _hover={{ bg: "gray.700", color: "white" }}
                _focus={{ boxShadow: "outline" }}
                _expanded={{ bg: "black", color: "white" }}
                bg={isOpen.value ? "black" : "white"}
                color={isOpen.value ? "white" : "black"}
                onClick={(e) => {
                  toggleDropdown();
                  mouseClick(e);
                }}
                justify="space-evenly"
                align="center"
                cursor="pointer"
                zIndex={isOpen ? 1000 : "auto"}
              >
                <Text fontSize="12px" fontWeight="500">
                  Actions{" "}
                </Text>
                {isOpen.value ? (
                  <Icon as={RxCross1} fontSize="14px" />
                ) : (
                  <Icon as={IoIosArrowDown} fontSize="16px" />
                )}
              </Flex>
              {isOpen.value &&
                createPortal(
                  <Flex
                    ref={menuRef}
                    direction="column"
                    p="20px 0px 10px"
                    w="207px"
                    bg="black"
                    color="white"
                    position="absolute"
                    top={`${menuPosition.top}px`}
                    left={`${menuPosition.left}px`}
                    gap="18px"
                    borderRadius="16px"
                    zIndex="1000"
                  >
                    <Text color="white" px="20px">
                      Actions:
                    </Text>
                    <Flex direction="column" gap="10px">
                      {filteredActions?.map((item: any) => (
                        <Flex
                          align="center"
                          w="100%"
                          justify="space-between"
                          color="white"
                          p="10px 20px"
                          _hover={{ bg: "gray.600", color: "gray.100" }}
                          cursor="pointer"
                          onClick={() => {
                            if (item.label === "Requirements") {
                              showDetail(data);
                            } else if (item.label === "Show Interest") {
                              handleShowInterest();
                            }
                            if (type === "engaged") {
                              handleEngaged(item.label);
                            }
                          }}
                        >
                          <Flex align="center" gap="8px">
                            <Icon as={item.icon} fontSize="21px" />
                            <Text fontSize="14px" fontWeight="500">
                              {item.label}
                            </Text>
                          </Flex>
                          {/* <Icon as={IoIosArrowForward} fontSize="12px" /> */}
                        </Flex>
                      ))}
                    </Flex>
                  </Flex>,
                  document.body
                )}
            </Flex>
          )}
        </Flex>
      </Flex>
      <Modal isOpen={isPolicyOpen} onClose={onPolicyClose} size="lg">
        <ModalOverlay />
        <ModalContent borderRadius="21px" pb="35px">
          <ModalHeader>Upload Policy</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex direction="column" padding="10px" gap="10px" align="center">
              <Flex w="100%" justify="center">
                <Flex
                  w="295px"
                  borderRadius="12px"
                  border="2px dotted gray"
                  padding="20px"
                  gap="12px"
                  direction="column"
                  align="center"
                  color="black"
                  onClick={handleUploadClick}
                  cursor="pointer"
                >
                  <input
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                  />
                  <Text fontWeight="600" fontSize="16px">
                    Upload PDF
                  </Text>
                  <Icon
                    as={IoCloudDownloadOutline}
                    fontSize="28px"
                    color={theme.color.NAVY_BLUE}
                  />
                  <Text fontWeight="400" fontSize="10px" textAlign="center">
                    Click to browse. Max file size: 25 MB.{" "}
                  </Text>
                  {file && (
                    <Text
                      fontWeight="400"
                      fontSize="10px"
                      textAlign="center"
                      color={theme.color.NAVY_BLUE}
                    >
                      <strong>Uploaded File :</strong>
                      {file?.name}
                    </Text>
                  )}
                </Flex>
              </Flex>

              <Button
                onClick={handleUploadPDF}
                w="fit-content"
                borderRadius="21px"
                colorScheme="green"
                mt="12px"
                isLoading={isSubmitting}
                isDisabled={isSubmitting}
              >
                Upload
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default BidderBidCard;
