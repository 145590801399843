import { Route, Routes, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import "./App.css";
import useFetch from "./partials/utils/Interceptor/Interceptor";
import { useEffect } from "react";
import { useStore } from "./store/store";

import PublicRoutes from "./routes/PublicRoutes";
import { BidderRoutes } from "./routes/bidder-routes";
import { ClientRoutes } from "./routes/client-routes";
import {
  useFetchUser,
} from "./partials/utils/User";
import Cookies from "js-cookie";

function App() {
  const { pathname } = useLocation();
  const user = useFetchUser();

  const {
    countries,
    setCountries,
    setProduct,
    setOccupancy,
    setBusinessType,
    setCompanyType,
  } = useStore();
  // const handleLogout = () => {
  //   Cookies.remove("jwt");
  //   Cookies.remove("user");
  //   window.location.href = "/login";
  // };
  function fetchMasterData() {
    useFetch
      .get(`masterdata`)
      .then((res) => {
        setCountries(res?.data?.country);
        setProduct(res?.data?.product);
        setOccupancy(res?.data?.occupancy);
        setBusinessType(res?.data?.businesstype);
        setCompanyType(res?.data?.companyType);
      })
      .catch((error) => {});
  }

  useEffect(() => {
    if(!countries?.length) fetchMasterData();   
  }, [pathname]);


  return (
    <Routes>
      <Route element={<PublicRoutes />}>
        <Route path="login" element={<Login />} />
        {/* <Route path="login-bidder" element={<Login />} />
        <Route path="login-client" element={<Login />} /> */}
      </Route>
      {user?.role_id === 3 ? (
        <Route path="/*" element={<BidderRoutes />} />
      ) : (
        <Route path="/*" element={<ClientRoutes />} />
      )}
    </Routes>
  );
}

export default App;
