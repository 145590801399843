import { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Input,
  Link,
  Text,
  useToast,
} from "@chakra-ui/react";
import Cookies from "js-cookie";

import FullDarkLogo from "../../partials/assets/images/full_logo_dark.svg";
import { ReactComponent as GoogleIcon } from "../../partials/assets/icons/google-icon.svg";
import { ReactComponent as WhatsAppIcon } from "../../partials/assets/icons/whatsapp-icon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useStore } from "../../store/store";
import { Authenticate, initOTPless } from "../../initOTPless";
import LoadingScreen from "../LoadingScreen";

const Login = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { setUser } = useStore();
  const localValue = localStorage.getItem("loginType");
  const [loginType, setLoginType] = useState<string>(
    localValue ? localValue : "2"
  );
  const [emailOrPhone, setEmailOrPhone] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = () => {
    if (isValidEmail(emailOrPhone)) {
      emailAuth();
    } else if (isValidPhone(emailOrPhone)) {
      phoneAuth();
    } else {
      toast({
        title: "Invalid email or phone format",
        description: "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const isValidEmail = (input: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input);

  const isValidPhone = (input: string) => /^\+?[1-9]\d{1,14}$/.test(input);
  const toggleLoginType = () => {
    if (loginType === "2") {
      setLoginType("3");
    } else {
      setLoginType("2");
    }
  };
  const phoneAuth = () => {
    Authenticate({ channel: "PHONE", phone: emailOrPhone });
    toast({
      title: "Email sent",
      description: `A message has been sent to ${emailOrPhone}. Please check your inbox.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const emailAuth = () => {
    Authenticate({ channel: "EMAIL", email: emailOrPhone });
    toast({
      title: "Email sent",
      description: `An email has been sent to ${emailOrPhone}. Please check your inbox.`,
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  function Login(
    token: string,
    name: string,
    location: string,
    country: string,
    email: string,
    mobile: string,
    type: string
  ) {
    setIsLoading(true);
    const localLoginType = localStorage.getItem("loginType");
    const Body = {
      email,
      mobile,
      role_id: localLoginType ? +localLoginType : loginType, //required  2 for client 3 for bidder
      name,
      // location: "Delhi",
      // country: "india",
      country,
      location,
      token,
      login_method: type,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}login`,
        Body
      )
      .then((res) => {
        toast({
          title: "User login successfully",
          description: "",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        Cookies.set("jwt", res?.data?.authorisation?.token);
        Cookies.set("user", JSON.stringify(res?.data?.user));
        setUser(res?.data?.user);
        navigate("/home");
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "please try again",
          description: "",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      })
      .finally(() => setIsLoading(false));
  }
  useEffect(() => initOTPless(handleUserData), []);
  const handleUserData = (otplessUser: any) => {
    const emailMap = otplessUser.identities?.find(
      (item: any) => item.identityType === "EMAIL"
    );

    const mobileMap = otplessUser.identities?.find(
      (item: any) => item.identityType === "MOBILE"
    )?.identityValue;
    const token = otplessUser.token;
    const email = emailMap?.identityValue;
    const mobile = mobileMap?.identityValue;
    const name = emailMap?.name || mobileMap?.name;
    const ipLocation = otplessUser?.network?.ipLocation;
    const location = ipLocation?.city?.name;
    const country = ipLocation?.country?.name;
    Login(
      token,
      name,
      location,
      country,
      email,
      mobile,
      mobileMap?.name ? "Mobile" : "Email"
    );
  };
  useEffect(() => {
    localStorage.setItem("loginType", loginType);
    const newTypeString = loginType === "2" ? "client" : "bidder";
    // navigate(`/login-${newTypeString}`);
  }, [loginType]);

  if (isLoading) {
    return <LoadingScreen />;
  }
  return (
    <Flex
      w="100%"
      height="100vh"
      className="login-gradient-bg"
      padding={{ base: "20px", lg: "0px" }}
    >
      <Flex
        justify="end"
        position="fixed"
        top="40px"
        right="40px"
        display={{ base: "flex", lg: "none" }}
      >
        <Button
          // leftIcon={<CompassIcon />}
          w="200px"
          bg="rgb(121 119 119 / 30%)"
          fontSize="16px"
          _hover={{ bg: "rgb(121 119 119 / 20%)" }}
          borderRadius="12px"
          onClick={toggleLoginType}
          type="button"
        >
          {loginType === "3" ? "Client" : "Bidder"} Portal
        </Button>
      </Flex>
      <Flex
        width={{ base: "100%", lg: "45%" }}
        height="100%"
        direction="column"
        align="center"
        justify="center"
        gap="18px"
        bg="white"
        borderRadius={{ base: "24px", lg: "0px 24px 24px 0px" }}
      >
        <Image src={FullDarkLogo} height="60px" width="150px" />
        <Flex
          direction="column"
          gap="15px"
          align="center"
          width="100%"
          maxW="346px"
        >
          <Flex direction="column" gap="4px" align="center" width="100%">
            <Text fontSize="24px" fontWeight="600">
              {loginType === "3" ? "Bidder" : "Client"} Platform
            </Text>
            <Text>Let's get started</Text>
          </Flex>
          <Input
            type="text"
            placeholder="Enter phone or email"
            value={emailOrPhone}
            onChange={(e) => setEmailOrPhone(e.target.value)}
            w="100%"
            height="46px"
          />
          <Button
            w="100%"
            colorScheme="black"
            bg="black"
            isDisabled={!emailOrPhone}
            onClick={handleContinue}
          >
            Continue
          </Button>
          <HStack w="full" justify="space-evenly">
            <Divider orientation="horizontal" border="1.5px solid black" />
            <Text
              fontSize="14px"
              fontWeight="600"
              //   width="55%"
              textAlign="center"
              letterSpacing="-0.12px"
            >
              OR
            </Text>
            <Divider orientation="horizontal" border="1.5px solid black" />
          </HStack>
          <Button
            width="100%"
            bg="white"
            border="1px solid gray"
            leftIcon={<GoogleIcon />}
            fontSize="16px"
            justifyContent="start"
            // onClick={() => oauth("GMAIL")}
            onClick={() =>
              Authenticate({ channel: "OAUTH", channelType: "GOOGLE" })
            }
          >
            Continue with Google
          </Button>
          <Button
            width="100%"
            bg="white"
            border="1px solid gray"
            leftIcon={<WhatsAppIcon />}
            fontSize="16px"
            justifyContent="start"
            // onClick={() => oauth("WHATSAPP")}
            onClick={() =>
              Authenticate({ channel: "OAUTH", channelType: "WHATSAPP" })
            }
          >
            Continue with WhatsApp
          </Button>
        </Flex>
        <Text fontSize="13px" mt="55px">
          By signing in, you agree to{" "}
          <Link textDecoration="underline" target="_blank" href="/terms">
            Terms and policy
          </Link>
        </Text>
      </Flex>
      <Flex
        flex={3}
        height="100%"
        direction="column"
        padding="27px 31px"
        display={{ base: "none", lg: "flex" }}
      >
        <Flex justify="end">
          <Button
            // leftIcon={<CompassIcon />}
            w="200px"
            bg="rgb(121 119 119 / 30%)"
            fontSize="16px"
            _hover={{ bg: "rgb(121 119 119 / 20%)" }}
            borderRadius="12px"
            onClick={toggleLoginType}
            type="button"
          >
            {loginType === "3" ? "Client" : "Bidder"} Portal
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Login;
