import { Link, useLocation } from "react-router-dom";
import {
  Avatar,
  Flex,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";

import FullDarkLogo from "../../partials/assets/images/full_logo_dark.svg";
import { useStore } from "../../store/store";
import Cookies from "js-cookie";
import useLogout from "../../partials/utils/useLogout";
import { useFetchUser } from "../../partials/utils/User";

const Sidebar = ({ children, sidebaroptions }: any) => {
  const { logoutHandler } = useLogout();
  const { pathname } = useLocation();
  const user = useFetchUser();
  const { step, setStep, selectedCategories, setSelectedCategories } =
    useStore();
  const toast = useToast();
  const handleLogout = () => {
    logoutHandler();
    Cookies.remove("jwt");
    Cookies.remove("user");
    window.location.href ='/login';
  };

  const showBlackBg = pathname.includes("/create-bid") && step <= 2;

  const handleNext = () => {
    if (!selectedCategories?.length) {
      toast({
        title: "Please select a category",
        description: "",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    setStep(step + 1);
  };

  return (
    <Flex
      // minH="100vh"
      // maxH="100vh"
      height="100vh"
      overflowY="auto"
      width="100%"
      bg="rgb(75 235 241)"
      direction="column"
      // justify="space-between"
    >
      <Flex
        // h={showBlackBg ? "calc(100% - 90px)" : "100%"}
        flex={1}
        width="100%"
        className="home-bg"
      >
        <Flex direction="column" w="295px" padding="23px 30px" gap="16px">
          <Image src={FullDarkLogo} height="71px" width="177px" />
          <Flex direction="column" gap="14px" flex={1}>
            <Flex direction="column" gap="10px">
              {sidebaroptions?.map((item: any, index: number) => (
                <Link key={index} to={item.link}>
                  <Flex
                    align="center"
                    gap="10px"
                    borderRadius="12px"
                    padding="12px 20px"
                    bg={pathname.includes(item.link) ? "white" : "transparent"}
                    onClick={() => {
                      if (item.label === "Create Bid") {
                        setSelectedCategories([]);
                        setStep(1);
                      }
                    }}
                  >
                    <Icon as={item.icon} fontSize="28px" />
                    <Text fontSize="16px" fontWeight="600">
                      {item.label}
                    </Text>
                  </Flex>
                </Link>
              ))}
            </Flex>
            {/* <Image
              src="https://placehold.co/224x401"
              height="401px"
              width="225px"
              borderRadius="24px"
            /> */}
          </Flex>
          <Flex
            align="center"
            gap="12px"
            borderRadius="12px"
            padding="12px 20px"
          >
            <Menu>
              <MenuButton>
                <Avatar name="" src="" size="sm" />
              </MenuButton>
              <MenuList>
                {/* <MenuGroup title='Profile'>
      <MenuItem>My Account</MenuItem>
      <MenuItem>Payments </MenuItem>
    </MenuGroup> */}
                <Flex px="7px" align="center" gap="5px">
                  <Avatar name="" src="" size="sm" />{" "}
                  <Text>{user?.name || ""}</Text>
                </Flex>
                <MenuDivider />
                {/* <MenuGroup title='Help'> */}
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                {/* </MenuGroup> */}
              </MenuList>
            </Menu>
          </Flex>
        </Flex>
        <Flex
          bg="white"
          borderRadius="24px 0px 0px 24px"
          flex="1"
          ps="5px"
          background="white"
          // maxH='100%'
          overflow="auto"
          // height=""
        >
          {children}
        </Flex>
      </Flex>
      {/* {showBlackBg && (
        <Flex
          w="100%"
          height="38px"
          justify="end"
          color="white"
          px="80px"
          bg="black"
        >
          <Flex gap="5px" align="center" cursor="pointer" onClick={handleNext}>
            <Text>Next</Text> <GoArrowRight />
          </Flex>
        </Flex>
      )} */}
    </Flex>
  );
};

export default Sidebar;
